import { postData, fetchDataFromApi, deleteData } from "../utils/api";

export const createOrder = async (payload) => {
  try {
    await postData(`/api/orders/create`, payload);
    const userId = payload.userid;
    const cartData = await fetchDataFromApi(`/api/cart?userId=${userId}`);
    for (const item of cartData) {
      await deleteData(`/api/cart/${item.id}`);
    }
    return true;
  } catch (error) {
    console.error("Error creating order:", error);
    throw error;
  }
};
