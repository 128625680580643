import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Product from "../../components/product";
import { CircularProgress, MenuItem, Select, Box, Pagination, Container, Grid, Typography, Paper, Button } from "@mui/material";

import { MyContext } from "../../App";
import { fetchDataFromApi } from "../../utils/api";

const SearchPage = (props) => {
  const [filterId, setFilterId] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [productData, setProductData] = useState([]); // Use this as main data source
  const [currentCat, setCurrentCat] = useState();
  const [sortOption, setSortOption] = useState(""); // State for selected sorting option
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(4);

  const context = useContext(MyContext);
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    setFilterId("");

    // Load initial products from context if available
    if (context?.searchItems?.length > 0) {
      setProductData(context.searchItems);
    }

    // Fetch category data (for context or additional info)
    fetchDataFromApi(`/api/category/${id}`).then((res) => {
      setCurrentCat(res);
      console.log(res);
    });
  }, [id, context.searchItems]); // Watch context.searchItems for changes

  const filterByRating = (rating) => {
    setisLoading(true);
    let apiEndPoint = "";

    if (window.location.href.includes("subCat")) {
      apiEndPoint = `/api/products/rating?rating=${rating}&subCatId=${id}&location=${localStorage.getItem("location")}`;
    }
    if (window.location.href.includes("category")) {
      apiEndPoint = `/api/products/rating?rating=${rating}&catId=${id}&location=${localStorage.getItem("location")}`;
    }

    fetchDataFromApi(apiEndPoint).then((res) => {
      setProductData(res);
      setisLoading(false);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  };

  const filterByPrice = (price) => {
    setisLoading(true);
    let api_EndPoint = "";

    if (filterId === "") {
      if (window.location.href.includes("subCat")) {
        api_EndPoint = `/api/products/fiterByPrice?minPrice=${price[0]}&maxPrice=${price[1]}&subCatId=${id}&location=${localStorage.getItem("location")}`;
      }
      if (window.location.href.includes("category")) {
        api_EndPoint = `/api/products/fiterByPrice?minPrice=${price[0]}&maxPrice=${price[1]}&catId=${id}&location=${localStorage.getItem("location")}`;
      }
    } else {
      api_EndPoint = `/api/products/fiterByPrice?minPrice=${price[0]}&maxPrice=${price[1]}&subCatId=${filterId}&location=${localStorage.getItem("location")}`;
    }

    fetchDataFromApi(api_EndPoint).then((res) => {
      setProductData(res);
      setisLoading(false);
    });
  };

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
    sortProducts(event.target.value);
  };

  const sortProducts = (option) => {
    let sortedData = [...productData];

    switch (option) {
      case "A-Z":
        sortedData.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case "Z-A":
        sortedData.sort((a, b) => b.name.localeCompare(a.name));
        break;
      case "Price Low to High":
        sortedData.sort((a, b) => a.price - b.price);
        break;
      case "Price High to Low":
        sortedData.sort((a, b) => b.price - a.price);
        break;
      case "New Arrivals":
        sortedData.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated));
        break;
      default:
        break;
    }
    setProductData(sortedData);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, Math.ceil(productData.length / itemsPerPage)));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const paginatedData = productData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
<Container>
  <Box my={4} mt={20}>
    <Typography variant="h4" component="h1" gutterBottom>
      We found <span style={{ color: "green" }}>{productData.length} items for you!</span>
    </Typography>
    <Box display="flex" justifyContent="flex-end" mb={2}>
      <Select
        value={sortOption}
        onChange={handleSortChange}
        displayEmpty
        variant="outlined"
      >
        <MenuItem value="">Sort By</MenuItem>
        <MenuItem value="A-Z">A-Z</MenuItem>
        <MenuItem value="Z-A">Z-A</MenuItem>
        <MenuItem value="Price Low to High">Price Low to High</MenuItem>
        <MenuItem value="Price High to Low">Price High to Low</MenuItem>
        <MenuItem value="New Arrivals">New Arrivals</MenuItem>
      </Select>
    </Box>
    <Grid container spacing={3}>
      {isLoading ? (
        <Grid item xs={12} display="flex" justifyContent="center">
          <CircularProgress color="inherit" />
        </Grid>
      ) : (
        paginatedData.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Paper elevation={3}>
              <Product item={item} />
            </Paper>
          </Grid>
        ))
      )}
    </Grid>
    <Box
      display="flex"
      justifyContent="center"
      mt={4}
      sx={{
        "@media (max-width: 768px)": {
          flexDirection: "column", // Stack the pagination in mobile view
          alignItems: "center",
        },
      }}
    >
      <Pagination
        count={Math.ceil(productData.length / itemsPerPage)}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        siblingCount={0}
        boundaryCount={1}
        size="small"
        sx={{
          display: { xs: "none", md: "flex" }, // Hide default pagination on mobile
        }}
      />
      <Box
        display={{ xs: "flex", md: "none" }} // Show next/previous buttons on mobile
        justifyContent="space-between"
        width="100%"
        mt={2}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextPage}
          disabled={currentPage === Math.ceil(productData.length / itemsPerPage)}
        >
          Next
        </Button>
      </Box>
    </Box>
  </Box>
</Container>

  );
};

export default SearchPage;
