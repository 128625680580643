
import { fetchDataFromApi, postData } from "../utils/api";

export const fetchProductReviews = async (productId) => {
  try {
    const response = await fetchDataFromApi(`/api/productReviews?productId=${productId}`);
    return response;
  } catch (error) {
    console.error("Error fetching product reviews:", error);
    throw error;
  }
};

export const addProductReview = async (review) => {
  try {
    const response = await postData(`/api/productReviews/add`, review);
    return response;
  } catch (error) {
    console.error("Error adding product review:", error);
    throw error;
  }
};
