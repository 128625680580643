import React, { useState, useContext, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { Button } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { postData } from "../../utils/api";
import { MyContext } from "../../App";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { firebaseApp } from "../../firebase";
import GoogleImg from "../../assets/images/google.png";

const googleProvider = new GoogleAuthProvider();
const auth = getAuth(firebaseApp);

const SignUp = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [userId, setUserId] = useState(""); // Store the user ID after signup
  const [formFields, setFormFields] = useState({
    name: "",
    email: "",

    password: "",
  });
  const [timer, setTimer] = useState(120); // Timer set to 2 minutes (120 seconds)
  const [canResend, setCanResend] = useState(false); // Resend button control
  const context = useContext(MyContext);
  const history = useNavigate();

  useEffect(() => {
    let interval;
    if (otpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
      setCanResend(true); // Enable resend after timer expires
    }
   
  
    return () => clearInterval(interval);
  }, [otpSent, timer]);

  const onChangeInput = (e) => {
    setFormFields((prevFields) => ({
      ...prevFields,
      [e.target.name]: e.target.value,
    }));
  };
  if (context.isLogin) {
    return <Navigate to="/" />;
  }
  const signUp = (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (formFields.name === "") {
        context.setAlertBox({ open: true, error: true, msg: "Name cannot be blank!" });
        return setIsLoading(false);
      }
      if (formFields.email === "") {
        context.setAlertBox({ open: true, error: true, msg: "Email cannot be blank!" });
        return setIsLoading(false);
      }
      if (formFields.phone === "" || formFields.phone.length !== 10) {
        context.setAlertBox({ open: true, error: true, msg: "Phone number must be 10 digits!" });
        return setIsLoading(false);
      }
      if (formFields.password === "" || formFields.password.length < 8) {
        context.setAlertBox({ open: true, error: true, msg: "Password must be combination of 8 characters or letters!" });
        return setIsLoading(false);
      }

      postData("/api/user/signup", formFields)
        .then((res) => {
          if (res.status === "PENDING") {
            setOtpSent(true); // OTP sent, show OTP input
            setUserId(res.userId); // Store user ID for OTP verification
            setTimer(120); // Reset the timer
            context.setAlertBox({ open: true, error: false, msg: "OTP sent to your email." });
          } else {
            context.setAlertBox({ open: true, error: true, msg: res.msg });
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error during signup:", error);
        });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const verifyOtp = (e) => {
    e.preventDefault();
    setIsLoading(true);

    postData("/api/user/verify-otp", { otp, userId })
      .then((res) => {
        if (res.status === "SUCCESS") {
          context.setAlertBox({ open: true, error: false, msg: "User verified successfully!" });
          history("/signIn");
        } else {
          context.setAlertBox({ open: true, error: true, msg: "Invalid OTP!" });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error during OTP verification:", error);
      });
  };

  const resendOtp = () => {
    // Function to resend OTP
    setIsLoading(true);
    postData("/api/user/resend-otp", { userId })
      .then((res) => {
        if (res.status === "PENDING") {
          setTimer(120); // Reset timer to 2 minutes
          setCanResend(false); // Disable resend button
          context.setAlertBox({ open: true, error: false, msg: "OTP resent to your email." });
        } else {
          context.setAlertBox({ open: true, error: true, msg: res.msg });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error resending OTP:", error);
      });
  };

  const signInWithGoogle = () => {
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        const fields = {
          name: user.providerData[0].displayName,
          email: user.providerData[0].email,
          password: null,
          images: user.providerData[0].photoURL,
          phone: user.providerData[0].phoneNumber,
        };

        postData("/api/user/authWithGoogle", fields)
          .then((res) => {
            if (res.error !== true) {
              localStorage.setItem("token", res.token);
              const userData = {
                name: res.user?.name,
                email: res.user?.email,
                userId: res.user?.id,
              };
              localStorage.setItem("user", JSON.stringify(userData));
              context.setAlertBox({ open: true, error: false, msg: res.msg });
              setTimeout(() => {
                history("/");
                context.setIsLogin(true);
                setIsLoading(false);
              }, 2000);
            } else {
              context.setAlertBox({ open: true, error: true, msg: res.msg });
              setIsLoading(false);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.error("Error during Google sign-in:", error);
          });
      })
      .catch((error) => {
        const errorMessage = error.message;
        context.setAlertBox({ open: true, error: true, msg: errorMessage });
      });
  };

  return (
    <section className="signIn mb-5">
      <div className="breadcrumbWrapper res-hide">
        <div className="container-fluid">
          <ul className="breadcrumb breadcrumb2 mb-0">
            <li><Link to="/">Home</Link></li>
            <li>SignUp</li>
          </ul>
        </div>
      </div>
      <div className="loginWrapper">
        <div className="card shadow">
          <Backdrop
            sx={{ color: "#000", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
            className="formLoader"
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <h3>SignUp</h3>
          {!otpSent ? (
            <form className="mt-4" onSubmit={signUp}>
              <div className="form-group mb-4 w-100">
                <TextField
                  id="Full Name"
                  type="text"
                  name="name"
                  label="Full Name"
                  className="w-100"
                  onChange={onChangeInput}
                />
              </div>
              <div className="form-group mb-4 w-100">
                <TextField
                  id="email"
                  type="email"
                  name="email"
                  label="Email"
                  className="w-100"
                  onChange={onChangeInput}
                />
              </div>
              <div className="form-group mb-4 w-100">
                <TextField
                
                  id="Phone"
                  type="number"
                  name="phone"
                  label="Phone +91"
                  className="w-100"
                  onChange={onChangeInput}
                  value={formFields.phone} // Ensure the phone field always starts with +91
                />
              </div>
              <div className="form-group mb-4 w-100">
                <div className="position-relative">
                  <TextField
                    id="password"
                    type={showPassword === false ? "password" : "text"}
                    name="password"
                    label="Password"
                    className="w-100"
                    onChange={onChangeInput}
                  />
                  <Button className="icon" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword === false ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </Button>
                </div>
              </div>
              <div className="form-group mt-5 mb-4 w-100">
                <Button type="submit" className="btn btn-g btn-lg w-100">Sign Up</Button>
              </div>
            </form>
          ) : (
            <form onSubmit={verifyOtp}>
              <div className="form-group mb-4 w-100">
                <TextField
                  id="otp"
                  type="text"
                  name="otp"
                  label="Enter OTP"
                  className="w-100"
                  onChange={(e) => setOtp(e.target.value)}
                />
              </div>
              <div className="form-group mt-5 mb-4 w-100">
                <Button type="submit" className="btn btn-g btn-lg w-100">Verify OTP</Button>
              </div>
              {timer > 0 ? (
                <p className="text-center">You can resend OTP in {timer} seconds</p>
              ) : (
                <div className="form-group mt-5 mb-4 w-100">
                  <Button
                    className="btn btn-g btn-lg w-100"
                    onClick={resendOtp}
                    disabled={!canResend}
                  >
                    Resend OTP
                  </Button>
                </div>
              )}
            </form>
          )}
          <div className="form-group mt-1 mb-4 w-100 signInOr">
            <p className="text-center">OR</p>
            <Button className="w-100" variant="outlined" onClick={signInWithGoogle}>
              <img src={GoogleImg} alt="Google sign-in" /> Sign In with Google
            </Button>
          </div>
          <p className="text-center">
            Already have an account? <b><Link to="/signIn">Sign In</Link></b>
          </p>
         
        </div>
      </div>
    </section>
  );
};

export default SignUp;
