import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, Button, Box, Typography, CircularProgress } from '@mui/material';
import { postData } from '../../utils/api'; // Ensure this API util is correctly set up

const ResetPassword = () => {
  const { token } = useParams(); // Extract token from URL
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state
  const history = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }
    setLoading(true); // Start loading
    try {
      const res = await postData('/api/user/reset-password', { token, newPassword });

      if (res.status === 200) {
        setMessage('Password reset successfully!');
        setTimeout(() => history('/signIn'), 3000); // Redirect after success
      } else {
        setMessage(res.message || 'Failed to reset password.'); // Handle backend message
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      setMessage('An error occurred. Please try again.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <Box 
      sx={{ maxWidth: 400, mx: 'auto', mt: 5, p: 3, boxShadow: 3, borderRadius: 2 }}
    >
      <Typography variant="h4" gutterBottom>
        Reset Password
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          type="password"
          label="New Password"
          variant="outlined"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          type="password"
          label="Confirm Password"
          variant="outlined"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
          sx={{ mb: 2 }}
        />
        <Button 
          type="submit" 
          variant="contained" 
          fullWidth 
          disabled={loading || !newPassword || !confirmPassword} // Disable while loading or if passwords are empty
        >
          {loading ? <CircularProgress size={24} /> : 'Reset Password'}
        </Button>
      </form>
      {message && (
        <Typography 
          sx={{ mt: 2, color: message.includes('successfully') ? 'green' : 'red' }}
        >
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default ResetPassword;
