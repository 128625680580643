import React, { useEffect, useState, useContext } from "react";
import "./style.css";
import Rating from "@mui/material/Rating";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CompareArrowsOutlinedIcon from "@mui/icons-material/CompareArrowsOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";

import { MyContext } from "../../App";
import { fetchDataFromApi, postData } from "../../utils/api";

const Product = (props) => {
  const [productData, setProductData] = useState();
  const [isAdded, setIsadded] = useState(false);
  const [addedInMyList, setAddedInMyList] = useState(false);
// console.log(productData)
  const context = useContext(MyContext);

  useEffect(() => {
    setProductData(props.item);
    console.log("productData",productData);
  }, [props.item]);

  const addToMyList = async (product) => {
    if (context?.isLogin === true) {
      const obj = {
        productTitle: product?.name,
        image: product?.images[0],
        rating: product?.rating,
        price: product?.price,
        productId: product?.id,
        userId: context?.user?.userId,
      };

      try {
        const userResponse = await fetchDataFromApi(`/api/user/${context?.user?.userId}`);
        if (userResponse.isBlocked) {
          context.setAlertBox({
            open: true,
            error: true,
            msg: "Your account is blocked. Please contact support.",
          });
          return;
        }

        const res = await postData(`/api/my-list/add`, obj);
        if (res.status !== false) {
          context?.setAlertBox({
            open: true,
            error: false,
            msg: "The product added in my list",
          });

          setAddedInMyList(true);

          const myListRes = await fetchDataFromApi(`/api/my-list?userId=${context?.user?.userId}`);
          context?.setMyListData(myListRes);
        }
      } catch (error) {
        context.setAlertBox({
          open: true,
          error: true,
          msg: "An error occurred. Please try again.",
        });
      }
    } else {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please Login to continue",
      });
    }
  };

  const handleMouseEnter = (productId) => {
    fetchDataFromApi(
      `/api/my-list?productId=${productId}&userId=${context?.user?.userId}`
    ).then((res) => {
      if (res?.length !== 0) {
        setAddedInMyList(true);
     
      }
    });
  };

  return (
    <div>
      {productData !== undefined && productData.category.isDeleted === false && (
        <div
          className="productThumb"
          onMouseEnter={() => handleMouseEnter(productData?.id)}
        >
          <div className="imgWrapper">
            <Link to={`/product/${productData.id}`}>
              <div className="wrapper mb-3">
                <img src={productData?.images[0]} className="w-100" />
              </div>

              <div className="overlay transition"></div>
            </Link>

            <ul className="actions list list-inline mb-0">
              <li className="list-inline-item">
                <a
                  className="cursor"
                  onClick={() => addToMyList(productData)}
                  tooltip={`${
                    addedInMyList === true
                      ? "Added in Wishlist"
                      : "Add to Wishlist"
                  }`}
                >
                  {addedInMyList === true ? (
                    <FavoriteIcon />
                  ) : (
                    <FavoriteBorderOutlinedIcon />
                  )}
                </a>
              </li>

              <li className="list-inline-item">
                <Link
                  to={`/product/${productData.id}`}
                  className="cursor"
                  tooltip="Quick View"
                >
                  <RemoveRedEyeOutlinedIcon />
                </Link>
              </li>
            </ul>
          </div>

          <div className="info">
            <span className="d-block catName">{productData?.catName}</span>
            <h4 className="title mb-0">
              <Link to={`/product/${productData.id}`}>
                {productData.name.substr(0, 25) + "..."}
              </Link>
            </h4>
            <Rating
              name="half-rating-read"
              value={parseFloat(productData?.rating)}
              precision={0.5}
              size="small"
              readOnly
            />
            <span className="brand d-block text-g">
              By <Link className="text-g">{productData?.brand}</Link>
            </span>
            <span className="brand d-block text-g mt-2" style={{ fontSize: "12px" }}>
              Stock <Link className="text-g " style={{ fontSize: "15px" }}>{productData?.countInStock}</Link>
            </span>

            <div className="d-flex align-items-center mt-3">
              <div className="d-flex align-items-center w-100">
                <span className="price text-g font-weight-bold">
                  Rs {productData?.price}
                </span>
                <span className="oldPrice ml-2">
                  Rs {productData?.oldPrice}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Product;
