import { deleteData, fetchDataFromApi, postData } from "../utils/api";

export const fetchCartDataWithStock = async (userId) => {
  const cartData = await fetchDataFromApi(`/api/cart?userId=${userId}`);
  const fetchStockPromises = cartData.map((item) =>
    fetchDataFromApi(`/api/products/${item.productId}`).then((product) => ({
      ...item,
      countInStock: product.countInStock, // Add stock information to each cart item
    }))
  );

  const updatedCartData = await Promise.all(fetchStockPromises);
  return updatedCartData;
};


export const addCartItem = async (userId, productId, quantity) => {
  const newItem = {
    userId,
    productId,
    quantity,
  };
  return await postData(`/api/cart`, newItem);
};

export const clearCart = async (userId) => {
  return await deleteData(`/api/cart/clear?userId=${userId}`);
};

export const removeCartItem = async (cartItemId) => {
  return await deleteData(`/api/cart/${cartItemId}`);
};

export const updateCartItem = async (cartItemId, updatedFields) => {
  return await postData(`/api/cart/${cartItemId}`, updatedFields);
};

export const fetchCartData = async (userId) => {
  return await fetchDataFromApi(`/api/cart?userId=${userId}`);
};


export const checkIsAddedInMyList = async (productId, userId) => {
  try {
    const response = await fetchDataFromApi(`/api/my-list?productId=${productId}&userId=${userId}`);
    return response?.length !== 0;
  } catch (error) {
    console.error("Error checking if product is added in my list:", error);
    return false;
  }
};

export const addToCart = async (cartFields) => {
  try {
    const response = await postData(`/api/cart/add`, cartFields);
    return response;
  } catch (error) {
    console.error("Error adding product to cart:", error);
    throw error;
  }
};
