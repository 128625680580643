// client/src/api/api.js
import axiosInstance from "./axiosInstance";

// Fetch Data
export const fetchDataFromApi = async (url) => {
  try {
    const { data } = await axiosInstance.get(url); 
    return data;
  } catch (error) {
    console.error("Fetch Data Error:", error);
    return error.response ? error.response.data : error;
  }
};

// Upload Image
export const uploadImage = async (url, formData) => {
  try {
    const { data } = await axiosInstance.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return data;
  } catch (error) {
    console.error("Image Upload Error:", error);
    return error.response ? error.response.data : error;
  }
};

// Post Data
export const postData = async (url, formData) => {
  try {
    const { data } = await axiosInstance.post(url, formData);
    return data;
  } catch (error) {
    console.error("Post Data Error:", error);
    return error.response ? error.response.data : error;
  }
};

// Edit Data
export const editData = async (url, updatedData) => {
  try {
    const { data } = await axiosInstance.put(url, updatedData);
    return data;
  } catch (error) {
    console.error("Edit Data Error:", error);
    return error.response ? error.response.data : error;
  }
};

// Delete Data
export const deleteData = async (url) => {
  try {
    const { data } = await axiosInstance.delete(url);
    return data;
  } catch (error) {
    console.error("Delete Data Error:", error);
    return error.response ? error.response.data : error;
  }
};

// Delete Images
export const deleteImages = async (url, image) => {
  try {
    const { data } = await axiosInstance.delete(url, { data: image });
    return data;
  } catch (error) {
    console.error("Delete Image Error:", error);
    return error.response ? error.response.data : error;
  }
};
