import {
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Modal from "react-modal";
import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "../../App";
import { useNavigate } from "react-router-dom";
import { fetchDataFromApi, postData, deleteData } from "../../utils/api";
import { fetchCartData } from "../../Services/cartServices";
import { deductWalletBalance, fetchUserData } from "../../Services/userServices";
import { createOrder } from "../../Services/orderServices";

const Checkout = () => {
  const [formFields, setFormFields] = useState({
    fullName: "",
    country: "",
    streetAddressLine1: "",
    streetAddressLine2: "",
    townCity: "",
    state: "",
    postCode: "",
    phoneNumber: "",
    email: "",
  });
  const [cartData, setCartData] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("razorpay");
  const [walletBalance, setWalletBalance] = useState(0);
  const [userAddresses, setUserAddresses] = useState([]);
  const [errors, setErrors] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [paymentErrorModalIsOpen, setPaymentErrorModalIsOpen] = useState(false);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState("");
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const context = useContext(MyContext);
  const history = useNavigate();

  // Retrieve totalAmount from localStorage or use context
  let totalAmount =
    parseFloat(localStorage.getItem("discountedTotal")) ||
    context.discountedTotal ||
    context.subtotal;
  totalAmount = Math.round(totalAmount * 100) / 100;
  useEffect(() => {
    window.scrollTo(0, 0);
    const user = JSON.parse(localStorage.getItem("user"));

    // Fetch cart data for the user
    fetchCartData(user?.userId).then((res) => {
      setCartData(res);
    });

    // Fetch user data including wallet balance and addresses
    fetchUserData(user?.userId).then((res) => {
      setWalletBalance(res.walletBalance);
      setUserAddresses(res.addresses);
    });
  }, []);

  const onChangeInput = (e) => {
    setFormFields({
      ...formFields,
      [e.target.name]: e.target.value,
    });
  };

  const onPaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;
    if (!formFields.fullName) {
      isValid = false;
      formErrors["fullName"] = "Please fill full name";
    }
    if (!formFields.country) {
      isValid = false;
      formErrors["country"] = "Please fill country";
    }
    if (!formFields.streetAddressLine1) {
      isValid = false;
      formErrors["streetAddressLine1"] = "Please fill street address";
    }
    if (!formFields.townCity) {
      isValid = false;
      formErrors["townCity"] = "Please fill city";
    }
    if (!formFields.state) {
      isValid = false;
      formErrors["state"] = "Please fill state";
    }
    if (!formFields.postCode) {
      isValid = false;
      formErrors["postCode"] = "Please fill zip code";
    }
    if (!formFields.phoneNumber) {
      isValid = false;
      formErrors["phoneNumber"] = "Please fill phone number";
    }
    if (!formFields.email) {
      isValid = false;
      formErrors["email"] = "Please fill email";
    }
    setErrors(formErrors);
    return isValid;
  };

  const getISTDate = () => {
    const utcDate = new Date();
    const istOffset = 5.5 * 60 * 60 * 1000;
    return new Date(utcDate.getTime() + istOffset);
  };

  const payNow = (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    if (totalAmount < 10) {
      setModalIsOpen(true);
      return;
    }

    const user = JSON.parse(localStorage.getItem("user"));

    if (paymentMethod === "razorpay") {
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: totalAmount * 100,
        currency: "INR",
        name: "Shop Me",
        description: "For testing purpose",
        handler: function (response) {
          const paymentId = response.razorpay_payment_id;
          handleConfirmPayment(paymentId);
          setSuccessMessage("Payment successful! Your order has been placed.");
          setSuccessModalIsOpen(true);
        },
        theme: { color: "#3399cc" },
        modal: {
          ondismiss: function () {
            setPaymentErrorMessage(
              "Payment failed. Retry or try another payment way."
            );
            setPaymentErrorModalIsOpen(true);
            handleConfirmPayment(null, "payment failed");
            context.getCartData();
          },
        },
      };
      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } else {
      // Update the confirmation message with formatted remaining balance
      const confirmationInfo = {
        cod: {
          title: "Cash on Delivery Confirmation",
          message:
            "Your order will be processed and delivered soon. Please keep the payment ready.",
        },
        wallet: {
          title: "Wallet Payment Confirmation",
          message: `Your order amount will be deducted from your wallet. Remaining balance: ₹${(
            walletBalance - totalAmount
          ).toFixed(2)}.`,
        },
      };

      setConfirmationTitle(confirmationInfo[paymentMethod].title);
      setConfirmationMessage(confirmationInfo[paymentMethod].message);

      setConfirmationTitle(confirmationInfo[paymentMethod].title);
      setConfirmationMessage(confirmationInfo[paymentMethod].message);
      setConfirmationModalIsOpen(true);
    }
  };

  const handleConfirmPayment = (paymentId = null, status = "pending") => {
    if (!paymentId) {
      paymentId =
        paymentMethod === "cod" ? `COD-${Date.now()}` : `WALLET-${Date.now()}`;
    }

    const user = JSON.parse(localStorage.getItem("user"));

    const payload = {
      method: paymentMethod,
      name: formFields.fullName,
      phoneNumber: formFields.phoneNumber,
      address: `${formFields.streetAddressLine1} ${formFields.streetAddressLine2}`,
      pincode: formFields.postCode,
      amount: totalAmount,
      paymentId,
      email: user.email,
      userid: user.userId,
      products: cartData.map((item) => ({
        productId: item.productId,
        quantity: item.quantity,
      })),
      date: getISTDate(),
      paymentMethod,
      status,
    };

    if (paymentMethod === "wallet") {
      // Deduct wallet balance for the user
      deductWalletBalance(user.userId, totalAmount).catch((error) => {
      }).catch((error) => {
        console.error("Error deducting wallet balance:", error);
      });
    }

    // Create a new order
    createOrder(payload).then(() => {
      context.getCartData();
    }).catch((error) => {
      console.error("Error creating order:", error);
    });

    setConfirmationModalIsOpen(false);
    if (status === "success") {
      setSuccessMessage("Payment successful! Your order has been placed.");
      setSuccessModalIsOpen(true);
    }
  };

  const handleAddressClick = (address) => {
    setFormFields({
      fullName: address.fullName,
      country: address.country,
      streetAddressLine1: address.streetAddressLine1,
      streetAddressLine2: address.streetAddressLine2,
      townCity: address.townCity,
      state: address.state,
      postCode: address.postCode,
      phoneNumber: address.phoneNumber,
      email: address.email,
    });
  };
  return (
    <section className="section pt-5 pb-4">
      <div className="container">
        <form className="checkoutForm" onSubmit={payNow}>
          <div className="row">
            <div className="col-md-8">
              <h2 className="hd">BILLING DETAIL</h2>

              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="form-group">
                    <TextField
                      label="Full Name *"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="fullName"
                      value={formFields.fullName}
                      onChange={onChangeInput}
                      error={!!errors.fullName}
                      helperText={errors.fullName}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <TextField
                      label="Country *"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="country"
                      value={formFields.country}
                      onChange={onChangeInput}
                      error={!!errors.country}
                      helperText={errors.country}
                    />
                  </div>
                </div>
              </div>
              <h6>Street address *</h6>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <TextField
                      label="House number and street name"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="streetAddressLine1"
                      value={formFields.streetAddressLine1}
                      onChange={onChangeInput}
                      error={!!errors.streetAddressLine1}
                      helperText={errors.streetAddressLine1}
                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      label="Apartment, suite, unit, etc. (optional)"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="streetAddressLine2"
                      value={formFields.streetAddressLine2}
                      onChange={onChangeInput}
                      error={!!errors.streetAddressLine2}
                      helperText={errors.streetAddressLine2}
                    />
                  </div>
                </div>
              </div>
              <h6>Town / City *</h6>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <TextField
                      label="City"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="townCity"
                      value={formFields.townCity}
                      onChange={onChangeInput}
                      error={!!errors.townCity}
                      helperText={errors.townCity}
                    />
                  </div>
                </div>
              </div>
              <h6>State / County *</h6>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <TextField
                      label="State"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="state"
                      value={formFields.state}
                      onChange={onChangeInput}
                      error={!!errors.state}
                      helperText={errors.state}
                    />
                  </div>
                </div>
              </div>
              <h6>Postcode / ZIP *</h6>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <TextField
                      label="ZIP Code"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="postCode"
                      value={formFields.postCode}
                      onChange={onChangeInput}
                      error={!!errors.postCode}
                      helperText={errors.postCode}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <TextField
                      label="Phone Number"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      type="number"
                      name="phoneNumber"
                      value={formFields.phoneNumber}
                      onChange={onChangeInput}
                      error={!!errors.phoneNumber}
                      helperText={errors.phoneNumber}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <TextField
                      label="Email Address"
                      type="email"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="email"
                      value={formFields.email}
                      onChange={onChangeInput}
                      error={!!errors.email}
                      helperText={errors.email}
                    />
                  </div>
                </div>
              </div>
              <h6>Payment Method *</h6>
              <RadioGroup
                aria-label="payment-method"
                name="paymentMethod"
                value={paymentMethod}
                onChange={onPaymentMethodChange}
              >
                <FormControlLabel
                  value="razorpay"
                  control={<Radio />}
                  label="Razorpay"
                />
                <FormControlLabel
                  value="cod"
                  control={<Radio />}
                  label="Cash on Delivery"
                  disabled={totalAmount > 1000}
                />
                <FormControlLabel
                  value="wallet"
                  control={<Radio />}
                  label={`Wallet (Balance: ${walletBalance.toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: "INR",
                    }
                  )})`}
                  disabled={walletBalance < totalAmount}
                />
              </RadioGroup>
            </div>
            <div className="col-md-4 cartRightBox ">
              <div className="card orderInfo">
                <h4 className="hd">YOUR ORDER</h4>
                <div className="table-responsive mt-3">
                  <table className="table table-borderless">
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>Subtotal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartData?.length !== 0 &&
                        cartData?.map((item, index) => {
                          return (
                            <tr>
                              <td>
                                {item?.productTitle?.substr(0, 20) + "..."}{" "}
                                <b>× {item?.quantity}</b>
                              </td>
                              <td>
                                {item?.subTotal?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "INR",
                                })}
                              </td>
                            </tr>
                          );
                        })}
                      <tr>
                        <td>Subtotal </td>

                        <td>
                          {totalAmount?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "INR",
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <Button type="submit" className="btn-g btn-lg w-100">
                  Checkout
                </Button>
              </div>
              <div className="card mt-4">
                <h4 className="hd">YOUR ADDRESSES</h4>
                <div className="mt-3">
                  {userAddresses?.length !== 0 &&
                    userAddresses?.map((address, index) => {
                      return (
                        <Card
                          key={index}
                          className="mb-2"
                          onClick={() => handleAddressClick(address)}
                        >
                          <CardContent>
                            <Typography variant="h6">
                              {address.fullName}
                            </Typography>
                            <Typography variant="body2">
                              {address.streetAddressLine1}{" "}
                              {address.streetAddressLine2}
                            </Typography>
                            <Typography variant="body2">
                              {address.townCity}, {address.state} -{" "}
                              {address.postCode}
                            </Typography>
                            <Typography variant="body2">
                              {address.country}
                            </Typography>
                          </CardContent>
                        </Card>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Payment Error"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            borderRadius: "10px",
            border: "1px solid #ccc",
            background: "#fff",
            maxWidth: "600px",
            width: "100%",
            lineHeight: "1.6",
          },
        }}
      >
        <h2>Payment Error</h2>
        <p>
          The total amount must be at least 10 INR to proceed with the payment.
        </p>
        <Button onClick={() => setModalIsOpen(false)}>Close</Button>
      </Modal>
      <Modal
        isOpen={confirmationModalIsOpen}
        onRequestClose={() => {setConfirmationModalIsOpen(false); context.getCartData();}}
        contentLabel="Payment Confirmation"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            borderRadius: "10px",
            border: "1px solid #ccc",
            background: "#fff",
            maxWidth: "600px",
            width: "100%",
            lineHeight: "1.6",
          },
        }}
      >
        <h2>{confirmationTitle}</h2>
        <p>{confirmationMessage}</p>
        <Button
          onClick={() => {
            handleConfirmPayment();
            history("/orders");
            context.getCartData();
          }}
        >
          Confirm Order
        </Button>
        <Button onClick={() => setConfirmationModalIsOpen(false)}>
          Cancel
        </Button>
      </Modal>
      <Modal
        isOpen={paymentErrorModalIsOpen}
        onRequestClose={() => {
          setPaymentErrorModalIsOpen(false);
          history("/orders"); // Navigate to the orders page after closing the modal
          context.getCartData(); // Refresh the cart
        }}
        contentLabel="Payment Error"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            borderRadius: "10px",
            border: "1px solid #ccc",
            background: "#fff",
            maxWidth: "600px",
            width: "100%",
            lineHeight: "1.6",
          },
        }}
      >
        <h2>Payment Error</h2>
        <p>{paymentErrorMessage}</p>
        <p>
          You can retry the payment from the <strong>Orders</strong> page.
        </p>
        <Button
          onClick={() => {
            setPaymentErrorModalIsOpen(false);
            history("/orders"); // Navigate to the orders page
            context.getCartData(); // Refresh the cart
          }}
        >
          Go to Orders
        </Button>
      </Modal>

      <Modal
        isOpen={successModalIsOpen}
        onRequestClose={() => setSuccessModalIsOpen(false)}
        contentLabel="Payment Success"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            borderRadius: "10px",
            border: "1px solid #ccc",
            background: "#fff",
            maxWidth: "600px",
            width: "100%",
            lineHeight: "1.6",
          },
        }}
      >
        <h2>Payment Success</h2>
        <p>{successMessage}</p>
        <Button
          onClick={() => {
            setSuccessModalIsOpen(false);
            context.getCartData(); // Refresh the cart
            history("/orders");
          }}
        >
          Close
        </Button>
      </Modal>
    </section>
  );
};
export default Checkout;
