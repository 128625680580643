import React, { useContext, useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Rating from "@mui/material/Rating";
import { Button, Select, MenuItem } from "@mui/material";
import QuantityBox from "../../components/quantityBox";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { MyContext } from "../../App";
import axios from "axios";
import { deleteData, editData, fetchDataFromApi } from "../../utils/api";
import emprtCart from "../../assets/images/empty.png";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import { emphasize, styled } from "@mui/material/styles";
import axiosInstance from "../../utils/axiosInstance";
import { fetchCartDataWithStock, removeCartItem, updateCartItem } from "../../Services/cartServices";
import { fetchAvailableCoupons, validateAndApplyCoupon } from "../../Services/couponServices";

// Breadcrumb styling
const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

const Cart = () => {
  const [cartData, setCartData] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [couponMessage, setCouponMessage] = useState("");
  const [availableCoupons, setAvailableCoupons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [outOfStockError, setOutOfStockError] = useState(false); // Track out of stock items
  const context = useContext(MyContext);
  const history = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const token = localStorage.getItem("token");
    if (!token) {
      history("/signIn");
    }

    const user = JSON.parse(localStorage.getItem("user"));
    
    // Fetch cart data and update subtotal on load
     fetchCartDataWithStock(user?.userId).then((updatedCartData) => {
      setCartData(updatedCartData);
      updateSubtotal(updatedCartData);
      setOutOfStockError(updatedCartData.some(item => item.countInStock === 0));
    });

    // Fetch available coupons
    fetchAvailableCoupons().then((res) => {
      setAvailableCoupons(res || []);
    });

    // Check if a coupon is already applied
    const appliedCoupon = localStorage.getItem("appliedCoupon");
    if (appliedCoupon) {
      setCouponCode(appliedCoupon);
      applyCoupon(appliedCoupon);
    }
  }, []);

  // Subtotal calculation function
  const updateSubtotal = (cartItems) => {
    const calculatedSubTotal = cartItems.reduce(
      (total, item) => total + (item.price * item.quantity || 0),
      0
    );
    setSubTotal(calculatedSubTotal);
    context.setDiscountedTotal(calculatedSubTotal); // Sync with context
  };

  const MAX_QUANTITY = 6;

  const quantity = (val) => {
    if (val > MAX_QUANTITY) {
      context.setAlertBox({
        open: true,
        error: true,
        msg: `Maximum ${MAX_QUANTITY} units allowed per product!`,
      });
    } else {
      context.getCartData();
    }
  };

  const selectedItem = useCallback((item, quantityVal) => {
    if (quantityVal > MAX_QUANTITY) {
      context.setAlertBox({
        open: true,
        error: true,
        msg: `Maximum ${MAX_QUANTITY} units allowed per product!`,
      });
    } else if (quantityVal > 0) {
      setIsLoading(true);

      const user = JSON.parse(localStorage.getItem("user"));
      const updatedFields = {
        productTitle: item?.productTitle,
        image: item?.image,
        rating: item?.rating,
        price: item?.price,
        quantity: quantityVal,
        subTotal: item?.price * quantityVal,
        productId: item?.productId,
        userId: user?.userId,
      };

      // Update the cart item with the new quantity and other details
      updateCartItem(item?._id, updatedFields).then(() => {  
        setIsLoading(false);
        // Fetch the updated cart data with stock information for the user
        fetchCartDataWithStock(user?.userId).then((updatedCartData) => {
          setCartData(updatedCartData);
          updateSubtotal(updatedCartData);
          setOutOfStockError(updatedCartData.some(item => item.countInStock === 0));
          context?.setCartItems(updatedCartData);
        });
      });
    }
  }, [context]);

  const removeItem = (id) => {
    setIsLoading(true);
    removeCartItem(id).then(() => { 
      context.setAlertBox({
        open: true,
        error: false,
        msg: "Item removed from cart!",
      });
      fetchCartDataWithStock(JSON.parse(localStorage.getItem("user"))?.userId).then((updatedCartData) => {
        setCartData(updatedCartData);
        updateSubtotal(updatedCartData);
        setOutOfStockError(updatedCartData.some(item => item.countInStock === 0));
        context?.setCartItems(updatedCartData);
      });
      setIsLoading(false);
    });
  };

  const applyCoupon = async (code = couponCode) => {
    const user = JSON.parse(localStorage.getItem("user")); // Assuming user data is stored here
    try {
      const response = await validateAndApplyCoupon(code, subTotal, user?.userId);
  
      if (response.success) {
        const discountAmount = response.discount;
        setDiscount(discountAmount);
        setCouponMessage(`Coupon Applied: ₹${discountAmount} off!`);
        const newDiscountedTotal = subTotal - discountAmount;
        context.setDiscountedTotal(newDiscountedTotal);
        localStorage.setItem("appliedCoupon", code);
      } else {
        setDiscount(0);
        setCouponMessage(response.message || "Invalid coupon code.");
        context.setDiscountedTotal(subTotal);
        localStorage.removeItem("appliedCoupon");
      }
    } catch (error) {
      setDiscount(0);
      setCouponMessage("Coupon expired.");
      context.setDiscountedTotal(subTotal);
      localStorage.removeItem("appliedCoupon");
      console.error("Error applying coupon:", error);
    }
  };
  const removeCoupon = () => {
    setCouponCode("");
    setDiscount(0);
    setCouponMessage("Coupon removed.");
    context.setDiscountedTotal(subTotal); // Reset to original subtotal
    localStorage.removeItem("appliedCoupon"); // Remove coupon from localStorage
    updateSubtotal(cartData); // Recalculate subtotal
  };

  const calculateTotal = () => {
    const discountedTotal = subTotal - discount;
    return discountedTotal.toLocaleString("en-US", {
      style: "currency",
      currency: "INR",
    });
  };

  return (
    <>
      <div className="breadcrumbWrapper mb-4">
        <div className="container-fluid">
          <Breadcrumbs aria-label="breadcrumb" className="ml-auto breadcrumbs_">
            <StyledBreadcrumb
              component="a"
              href="/"
              label="Home"
              icon={<HomeIcon fontSize="small" />}
            />
            <StyledBreadcrumb component="a" label="Shop" />
            <StyledBreadcrumb label="Cart" />
          </Breadcrumbs>
        </div>
      </div>

      <section className="cartSection mb-5">
        <div className="container-fluid">
          <div className="row">
            <div
              className={`${
                context?.cartItems?.length !== 0 ? "col-md-8" : "col-md-12"
              }`}
            >
              <div className="d-flex align-items-center w-100">
                <div className="left">
                  <h1 className="hd mb-0">Your Cart</h1>
                  <p>
                    There are{" "}
                    <span className="text-g">
                      <b>{context?.cartItems?.length}</b>
                    </span>{" "}
                    products in your cart
                  </p>
                </div>
              </div>

              {context?.cartItems?.length !== 0 ? (
                <>
                  <div className="cartWrapper mt-4">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Unit Price</th>
                            <th>Quantity</th>
                            <th>Subtotal</th>
                            <th>Remove</th>
                          </tr>
                        </thead>

                        <tbody>
                          {cartData?.length !== 0 &&
                            cartData?.map((item, index) => (
                              <tr key={index}>
                                <td width={"50%"}>
                                  <div className="d-flex align-items-center">
                                    <div className="img">
                                      <Link to={`/product/${item.productId}`}>
                                        <img
                                          src={item?.image}
                                          className="w-100"
                                          alt="product"
                                        />
                                      </Link>
                                    </div>

                                    <div className="info pl-4">
                                      <Link to={`/product/${item.productId}`}>
                                        <h4>
                                          {item?.productTitle?.substr(0, 50) +
                                            "..."}
                                        </h4>
                                      </Link>
                                      <Rating
                                        name="half-rating-read"
                                        value={parseFloat(item?.rating)}
                                        precision={0.5}
                                        readOnly
                                      />{" "}
                                      <span className="text-light">
                                        ({parseFloat(item?.rating)})
                                      </span>
                                      <p className="text-light">
                                        {item.countInStock > 0
                                          ? `In Stock: ${item.countInStock}`
                                          : "Out of Stock"}
                                      </p>
                                    </div>
                                  </div>
                                </td>

                                <td width="20%">
                                  <span>Rs: {item?.price}</span>
                                </td>

                                <td>
                                  <QuantityBox
                                    quantity={quantity}
                                    item={item}
                                    selectedItem={selectedItem}
                                    value={item?.quantity}
                                  />
                                </td>

                                <td>
                                  <span className="text-g">
                                    Rs. {item?.subTotal}
                                  </span>
                                </td>

                                <td align="center">
                                  <span
                                    className="cursor"
                                    onClick={() => removeItem(item?._id)}
                                  >
                                    <DeleteOutlineOutlinedIcon />
                                  </span>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <br />

                  <div className="d-flex align-items-center">
                    <Link to="/">
                      <Button className="btn-g">
                        <KeyboardBackspaceIcon /> Continue Shopping
                      </Button>
                    </Link>
                  </div>
                </>
              ) : (
                <div className="empty d-flex align-items-center justify-content-center flex-column">
                  <img
                    src={emprtCart}
                    alt="empty cart"
                    width="150"
                    height="150px"
                  />
                  <h3>Your Cart is currently empty</h3>
                  <br />
                  <Link to="/">
                    <Button className="btn-g bg-g btn-lg btn-big btn-round">
                      <HomeIcon /> &nbsp; Continue Shopping
                    </Button>
                  </Link>
                </div>
              )}
            </div>

            {context?.cartItems?.length !== 0 && (
              <div className="col-md-4 cartRightBox">
                <div className="card p-4 ">
                  <div className="d-flex align-items-center mb-4">
                    <h5 className="mb-0 text-light">Subtotal</h5>
                    <h3 className="ml-auto mb-0 font-weight-bold">
                      <span className="text-g">
                        {subTotal.toLocaleString("en-US", {
                          style: "currency",
                          currency: "INR",
                        })}
                      </span>
                    </h3>
                  </div>

                  <div className="coupon-section">
                    <h5 className="text-light">Have a Coupon Code?</h5>
                    <Select
                      label="Select Coupon Code"
                      variant="outlined"
                      fullWidth
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                    >
                      {availableCoupons.map((coupon) => (
                        <MenuItem key={coupon._id} value={coupon.code}>
                          <span style={{ fontWeight: "bold" }}>{coupon.code}</span> - <span style={{ color: "green" }}>{coupon.discount}% off</span> (up to <span style={{ color: "red" }}>{coupon.maxDiscountPrice} rs</span>)
                        </MenuItem>
                      ))}
                      {couponCode && !availableCoupons.some(coupon => coupon.code === couponCode) && (
                        <MenuItem value={couponCode}>
                          {couponCode} - Already Applied
                        </MenuItem>
                      )}
                    </Select>
                    <Button
                      className="btn-g mt-2"
                      onClick={() => applyCoupon(couponCode)}
                      disabled={!couponCode}
                    >
                      Apply Coupon
                    </Button>
                    <Button
                      className="btn-g mt-2 remove-coupon-btn"
                      onClick={removeCoupon}
                      disabled={!couponCode}
                    >
                      Remove Coupon
                    </Button>
                    {couponMessage && <p>{couponMessage}</p>}
                  </div>

                  <div className="d-flex align-items-center mb-4">
                    <h5 className="mb-0 text-light">Total</h5>
                    <h3 className="ml-auto mb-0 font-weight-bold">
                      <span className="text-g">{calculateTotal()}</span>
                    </h3>
                  </div>

                  <br />
                  <Link to={outOfStockError ? "#" : "/checkout"}>
                    <Button className="btn-g btn-lg" disabled={outOfStockError}>
                      Proceed To CheckOut
                    </Button>
                  </Link>
                  {outOfStockError && (
                    <p className="text-danger">
                      Some items in your cart are out of stock. Please remove
                      them to proceed.
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Cart;
