import { deleteData, fetchDataFromApi, postData } from "../utils/api";

export const fetchUserData = async (userId) => {
  return await fetchDataFromApi(`/api/user/${userId}`);
};

export const deductWalletBalance = async (userId, walletBalance) => {
  const payload = {
    userId,
    walletBalance,
  };
  return await postData(`/api/user/deductwallet`, payload);
};


export const checkProductInList = async (productId, userId) => {
  try {
    const response = await fetchDataFromApi(`/api/my-list?productId=${productId}&userId=${userId}`);
    return response?.length !== 0;
  } catch (error) {
    console.error("Error checking if product is added in my list:", error);
    return false;
  }
};

export const fetchUserDetails = async (user) => {
  if (!user || !user.userId) {
    throw new Error("Invalid user object");
  }
  return await fetchDataFromApi(`/api/user/${user.userId}`);
};
export const editUserData = async (user, data) => {
  if (!user || !user.userId) {
    throw new Error("Invalid user object");
  }
  const payload = {
    ...data,
  };
  return await postData(`/api/user/${user.userId}`, payload);
};
export const editData = async (user, updatedFields) => {
  if (!user || !user.userId) {
    throw new Error("Invalid user object");
  }
  const payload = {
    ...updatedFields,
  };
  return await postData(`/api/user/${user.userId}`, payload);
};
export const changeUserPassword = async (user, newPassword) => {
  if (!user || !user.userId) {
    throw new Error("Invalid user object");
  }
  const payload = {
    password: newPassword,
  };
  return await postData(`/api/user/changePassword/${user.userId}`, payload);
};

export const editUserAddress = async (user, editingAddressId, formFields) => {
  if (!user || !user.userId) {
    throw new Error("Invalid user object");
  }
  const payload = {
    address: formFields.address,
  };
  return await postData(`/api/user/${user.userId}/address/${editingAddressId}`, payload);
};
export const addUserAddress = async (user, formFields) => {
  if (!user || !user.userId) {
    throw new Error("Invalid user object");
  }
  const payload = {
    address: formFields.address,
  };
  return await postData(`/api/user/${user.userId}/address`, payload);
};

export const deleteUserAddress = async (user, addressId) => {
  if (!user || !user.userId) {
    throw new Error("Invalid user object");
  }
  return await deleteData(`/api/user/${user.userId}/address/${addressId}`);
};



export const getUserData = async (userId) => {
  if (!userId) {
    throw new Error("Invalid user ID");
  }
  try {
    const response = await fetchDataFromApi(`/api/user/${userId}`);
    return response;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

