import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { fetchDataFromApi } from "../../utils/api";
import {
  Typography,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Avatar,
  Button,
  CircularProgress,
  Container,
  Card,
  CardContent,
} from "@mui/material";

const Orderdetail = () => {
  const { orderId } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (orderId) {
      fetchDataFromApi(`/api/orders/${orderId}`)
        .then((res) => {
          setOrderDetails(res);
        })
        .catch((error) => {
          console.error("Error fetching order details:", error);
        });
    }
  }, [orderId]);

  if (!orderDetails) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  const formatCurrency = (value) => {
    return !isNaN(parseFloat(value)) ? parseFloat(value).toFixed(2) : "0.00";
  };

  const calculateCouponDiscount = () => {
    const subtotal = orderDetails.products.reduce((acc, product) => acc + parseFloat(product.subTotal), 0);
    const totalAmount = parseFloat(orderDetails.amount);
    const discount = subtotal - totalAmount;
    const discountPercentage = subtotal > 0 ? ((discount / subtotal) * 100).toFixed(2) : "0.00";
    return { discount, discountPercentage };
  };

  const { discount, discountPercentage } = calculateCouponDiscount();

  const getPaymentMethod = (paymentId) => {
    if (paymentId.includes("WALLET")) return "Wallet";
    if (paymentId.includes("pay")) return "Razorpay";
    if (paymentId.includes("COD")) return "Cash on Delivery";
    return "Unknown";
  };

  return (
    <Container maxWidth="md" sx={{ padding: "40px 0" }}>
      <Card sx={{ marginBottom: 3 }}>
        <CardContent>
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            sx={{ marginBottom: 2, backgroundColor: "#35ac75", color: "white", "&:hover": { backgroundColor: "#2e8b57" } }}
          >
            Back
          </Button>

          <Typography variant="h4" gutterBottom>
            Order Details
          </Typography>

          <Typography variant="h6">Order ID: {orderDetails.id}</Typography>
          <Typography variant="body1">
            Payment ID: {orderDetails.paymentId} ({getPaymentMethod(orderDetails.paymentId)})
          </Typography>
          <Typography variant="body1">Customer Name: {orderDetails.name}</Typography>
          <Typography variant="body1">Phone Number: {orderDetails.phoneNumber}</Typography>
          <Typography variant="body1">Email: {orderDetails.email}</Typography>
          <Typography variant="body1">Address: {orderDetails.address}</Typography>
          <Typography variant="body1">Status: {orderDetails.status}</Typography>
          <Typography variant="body1">Order Date: {orderDetails.date?.split("T")[0]}</Typography>
          <Typography variant="body1">Total Amount: ₹{formatCurrency(orderDetails.amount)}</Typography>
          {discount > 0 && (
            <Typography variant="body1">Coupon Discount: ₹{formatCurrency(discount)} ({discountPercentage}%)</Typography>
          )}
        </CardContent>
      </Card>

      <Typography variant="h6" sx={{ marginTop: 3 }}>
        Products
      </Typography>

      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product ID</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Subtotal</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderDetails.products.map((product, index) => (
              <TableRow key={index}>
                <TableCell>{product.productId || "No Product ID"}</TableCell>
                <TableCell>{product.productTitle || "No Title"}</TableCell>
                <TableCell>
                  <Avatar src={product.image || ""} variant="rounded" />
                </TableCell>
                <TableCell>{product.quantity || 0}</TableCell>
                <TableCell>₹{formatCurrency(product.price)}</TableCell>
                <TableCell>₹{formatCurrency(product.subTotal)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default Orderdetail;
