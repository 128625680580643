import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Button from '@mui/material/Button';
import { useContext, useEffect, useState } from "react";
import { MyContext } from "../../App";

const QuantityBox = (props) => {

    const [inputVal, setInputVal] = useState(1);
    const [isMaxQuantity, setIsMaxQuantity] = useState(false);

    const context = useContext(MyContext);

    useEffect(() => {
        if (props?.value !== undefined && props?.value !== null && props?.value !== "") {
            setInputVal(parseInt(props?.value))
        }
    }, [])

    const MAX_QUANTITY = 6;

    const minus = () => {
        if (inputVal !== 1 && inputVal > 0) {
            setInputVal(inputVal - 1);
        }
        context.setAlertBox({
            open: false,
        })
        setIsMaxQuantity(false);
    }

    const plus = () => {
        let stock = parseInt(props.item.countInStock);
        if (inputVal < stock && inputVal < MAX_QUANTITY) {
            setInputVal(inputVal + 1);
        } else if (inputVal >= stock) {
            context.setAlertBox({
                open: true,
                error: true,
                msg: "The quantity exceeds available stock"
            });
            setIsMaxQuantity(true);
        } else {
            context.setAlertBox({
                open: true,
                error: true,
                msg: "You can't add more than 6 units"
            });
            setIsMaxQuantity(true);
        }
    }

    useEffect(() => {
        if (props.quantity) {
            props.quantity(inputVal)
        }

        if (props.selectedItem) {
            props.selectedItem(props.item, inputVal);
        }

        let countInStock = parseInt(props.item.countInStock);
        if (inputVal > countInStock) {
            context.setAlertBox({
                open: true,
                error: true,
                msg: "The quantity is greater than product count in stock"
            })
        } else {
            context.setAlertBox({
                open: false,
            })
        }

    }, [inputVal]);

    return (
        <div className='quantityDrop d-flex align-items-center'>
            <Button onClick={minus}><RemoveIcon /></Button>
            <input type="text" value={inputVal} readOnly />
            <Button onClick={plus} disabled={isMaxQuantity}><AddIcon /></Button>
        </div>
    )
}

export default QuantityBox;