import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PaymentSuccess = () => {
  const navigate = useNavigate();

  const handleGoToOrders = () => {
    navigate('/orders');
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      bgcolor="#f5f5f5"
      p={3}
    >
      <Typography variant="h4" gutterBottom>
        Payment Successful!
      </Typography>
      <Typography variant="body1" gutterBottom>
        Thank you for your purchase. Your order has been placed successfully.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleGoToOrders}
        sx={{ mt: 2 }}
      >
        Go to My Orders
      </Button>
    </Box>
  );
};

export default PaymentSuccess;
