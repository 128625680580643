// src/components/RequireAuth.js
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { MyContext } from "../App";

const RequireAuth = ({ component: Component, ...rest }) => {
  const { isLogin, userData } = useContext(MyContext);

  // Redirect to sign-in if not logged in, or to an error page if the user is blocked
  // if (!isLogin) return <Navigate to="/signIn" />;
  if (userData?.isBlocked) {
    alert("Your account is blocked. Please contact support.");
    return <Navigate to="/" />;
  }

  return <Component {...rest} />;
};

export default RequireAuth;
