import axiosInstance from "../utils/axiosInstance";

// Fetch available coupons
export const fetchAvailableCoupons = async () => {
  try {
    const response = await axiosInstance.get(`/api/coupons`);
    return response.data.coupons || [];
  } catch (error) {
    console.error("Error fetching available coupons:", error);
    return [];
  }
};

// Validate and apply coupon
export const validateAndApplyCoupon = async (code, subTotal, userId) => {
  try {
    const response = await axiosInstance.post(`/api/coupons/validate`, {
      couponCode: code,
      totalPrice: subTotal,
      userId: userId,
    });

    return response.data;
  } catch (error) {
    console.error("Error validating coupon:", error);
    throw error;
  }
};
