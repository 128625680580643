import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import Rating from "@mui/material/Rating";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { MyContext } from "../../App";
import { fetchDataFromApi } from "../../utils/api";

const Sidebar = (props) => {
  const [value, setValue] = useState([100, 150000]);
  const [sortOption, setSortOption] = useState("popularity");
  const [homeSideBanners, setHomeSideBanners] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const context = useContext(MyContext);
  const { id } = useParams();

  // Initialize filters and banners
  useEffect(() => {
    props.filterByPrice(value, id);

    // Fetch banners once on mount
    fetchDataFromApi("/api/homeSideBanners").then((res) => {
      setHomeSideBanners(res);
    });
  }, [id]);

  // Handle price slider change
  const handlePriceChange = (newValue) => {
    setValue(newValue);
    props.filterByPrice(newValue, id);
  };

  // Handle sort option change
  const handleSortChange = (e) => {
    const selectedSort = e.target.value;
    setSortOption(selectedSort);
    props.sortBy(selectedSort);
  };

  // Handle rating filter
  const filterByRating = (rating) => {
    props.filterByRating(rating);
    context.setIsOpenFilters(false);
  };

  // Handle search action
  const handleSearch = () => {
    props.searchBy(searchTerm); // Pass search term to parent function
  };

  return (
    <div className={`sidebar ${context.isOpenFilters ? "open" : ""}`}>
      <div className="card border-0 shadow">
        <h3>Category</h3>
        <div className="catList">
          {context?.categories?.categoryList?.map((cat, index) => (
            <Link to={`/products/category/${cat?.id}`} key={index}>
              <div className="catItem d-flex align-items-center">
                <span className="img">
                  <img src={cat?.images[0]} width={30} alt="category" />
                </span>
                <h4 className="mb-0 ml-3 mr-3 text-capitalize">
                  {cat?.name}
                </h4>
              </div>
            </Link>
          ))}
        </div>
      </div>

      <div className="card border-0 shadow searchCard">
        <h3 className="mb-4">Search Products</h3>
        <div className="searchInput">
          <input
            type="text"
            className="form-control"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button className="btn btn-primary mt-2" onClick={handleSearch}>
            Search
          </button>
        </div>
      </div>

      <div className="card border-0 shadow priceCard">
        <h3 className="mb-4">Filter by price</h3>
        <RangeSlider value={value} onInput={handlePriceChange} min={100} max={150000} step={5} />
        <div className="d-flex pt-2 pb-2 priceRange">
          <span>
            From: <strong className="text-success">Rs: {value[0]}</strong>
          </span>
          <span className="ml-auto">
            To: <strong className="text-success">Rs: {value[1]}</strong>
          </span>
        </div>
      </div>

      <div className="card border-0 shadow sortCard">
        <h3 className="mb-4">Sort By</h3>
        <select className="form-control" value={sortOption} onChange={handleSortChange}>
          <option value="popularity">Sort</option>
          <option value="price-asc">Price: Low to High</option>
          <option value="price-desc">Price: High to Low</option>
          <option value="rating">Average Ratings</option>
          <option value="featured">Featured</option>
          <option value="new-arrivals">New Arrivals</option>
          <option value="a-z">A-Z</option>
          <option value="z-a">Z-A</option>
        </select>
      </div>

      <div className="card border-0 shadow filters">
        <h5>Filter By Ratings</h5>
        <ul className="pl-2 mb-0">
          {[5, 4, 3, 2, 1].map((rating) => (
            <li
              key={rating}
              className="cursor"
              onClick={() => filterByRating(rating)}
            >
              <Rating name="read-only" value={rating} readOnly size="small" />
            </li>
          ))}
        </ul>
      </div>

      <div className="sidebarAds">
        {homeSideBanners?.map((item, index) => (
          <div className="banner mb-3" key={index}>
            <Link
              to={`/products/${
                item.subCatId ? `subCat/${item.subCatId}` : `category/${item.catId}`
              }`}
            >
              <img src={item.images[0]} className="w-100 transition" alt="banner img" />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
