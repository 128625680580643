import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { postData } from '../../utils/api';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await postData('/api/user/forgot-password', { email });
      if (res.error) {
        setMessage(res.msg);
      } else {
        setMessage('Password reset link has been sent to your email.');
      }
    } catch (err) {
      console.error("Error sending password reset link:", err);
      setMessage('Failed to send password reset link.');
    }
  };

  return (
    <Box sx={{ maxWidth: 400, mx: 'auto', mt: 5, p: 3, boxShadow: 3, borderRadius: 2 }}>
      <Typography variant="h4" gutterBottom>
        Forgot Password
      </Typography>
      <Typography variant="body1" gutterBottom>
        Enter your email to receive a reset link.
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          type="email"
          label="Email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          sx={{ mb: 2 }}
        />
        <Button type="submit" variant="contained" fullWidth>
          Send Reset Link
        </Button>
      </form>
      {message && <Typography sx={{ mt: 2 }}>{message}</Typography>}
    </Box>
  );
};

export default ForgotPassword;
