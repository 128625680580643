import { fetchDataFromApi } from "../utils/api";

export const fetchRelatedProducts = async (subCatId, location) => {
  try {
    const response = await fetchDataFromApi(`/api/products/subCatId?subCatId=${subCatId}&location=${location}`);
    return response?.products || [];
  } catch (error) {
    console.error("Error fetching related products:", error);
    throw error;
  }
};
export const fetchProductDetails = async (productId) => {
  try {
    const response = await fetchDataFromApi(`/api/products/${productId}`);
    return response;
  } catch (error) {
    console.error("Error fetching product details:", error);
    throw error;
  }
};
