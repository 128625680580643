import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { IoMdCloudUpload } from "react-icons/io";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import {
  deleteData,
  deleteImages,
  editData,
  fetchDataFromApi,
  postData,
  uploadImage,
} from "../../utils/api";

import { MyContext } from "../../App";

import NoUserImg from "../../assets/images/no-user.jpg";
import CircularProgress from "@mui/material/CircularProgress";
import { Delete, Edit } from "@mui/icons-material";
import { Card, CardContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Modal from "react-modal";
Modal.setAppElement("#root");
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MyAccount = () => {
  const [isLogin, setIsLogin] = useState(false);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const history = useNavigate();

  const context = useContext(MyContext);

  const [isLoading, setIsLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [previews, setPreviews] = useState([]);
  const [userData, setUserData] = useState([]);

  const formdata = new FormData();
  const [walletTransactions, setWalletTransactions] = useState([]);
  const [loadingTransactions, setLoadingTransactions] = useState(true);
  
  const [formFields, setFormFields] = useState({
    name: "",
    email: "",
    phone: "",
    images: [],
    isAdmin: false,
    password: "",
    address: {
      fullName: "",
      country: "",
      streetAddressLine1: "",
      streetAddressLine2: "",
      townCity: "",
      state: "",
      postCode: "",
    },
  });

  const [fields, setFields] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });

  const [editingAddressId, setEditingAddressId] = useState(null);
  const [walletAmount, setWalletAmount] = useState("");
  const [walletError, setWalletError] = useState("");

  const [errors, setErrors] = useState({
    name: "",
    phone: "",
    fullName: "",
    country: "",
    streetAddressLine1: "",
    streetAddressLine2: "",
    townCity: "",
    state: "",
    postCode: "",
  });

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  useEffect(() => {
    const fetchWalletTransactions = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user || !user.userId) {
        console.error("Invalid user ID");
        return;
      }
    
      try {
        const response = await fetchDataFromApi(`/api/user/${user.userId}/wallet-history`);
        setWalletTransactions(response.walletHistory || []);
      } catch (error) {
        context.setAlertBox({ open: true, error: true, msg: "Failed to fetch wallet transactions" });
        setWalletTransactions([]);
      } finally {
        setLoadingTransactions(false);
      }
    };
    
  
    fetchWalletTransactions();
  }, []);
  
  useEffect(() => {
    window.scrollTo(0, 0);
    const token = localStorage.getItem("token");
    if (token !== "" && token !== undefined && token !== null) {
      setIsLogin(true);
    } else {
      history("/signIn");
    }

    deleteData("/api/imageUpload/deleteAllImages");
    const user = JSON.parse(localStorage.getItem("user"));

    fetchDataFromApi(`/api/user/${user?.userId}`).then((res) => {
      setUserData(res);
      setPreviews(res.images);

      setFormFields({
        name: res.name,
        email: res.email,
        phone: res.phone,
        images: res.images, // Ensure images are retained
        address: res.address || {
          fullName: "",
          country: "",
          streetAddressLine1: "",
          streetAddressLine2: "",
          townCity: "",
          state: "",
          postCode: "",
        },
      });
    }).catch((error) => {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Failed to fetch user data",
      });
    });

  }, [context, history]);

  const changeInput = (e) => {
    const { name, value } = e.target;
    let error = "";

    if (name === "name") {
      if (!/^[a-zA-Z\s]*$/.test(value)) {
        error = "Only letters are allowed";
      }
    } else if (name === "phone") {
      if (!/^\d+$/.test(value)) {
        error = "Only numbers are allowed";
      } else if (value.length !== 10) {
        error = "Phone number must be 10 digits";
      }
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));

    setFormFields((prevFormFields) => ({
      ...prevFormFields,
      [name]: value,
    }));
  };

  const changeAddressInput = (e) => {
    const { name, value } = e.target;
    let error = "";

    if (name === "fullName" || name === "country" || name === "townCity" || name === "state") {
      if (!/^[a-zA-Z\s]*$/.test(value)) {
        error = "Only letters are allowed";
      }
    } else if (name === "postCode") {
      if (!/^\d+$/.test(value)) {
        error = "Only numbers are allowed";
      }
    } else if (name === "streetAddressLine1" || name === "streetAddressLine2") {
      if (!/^[a-zA-Z0-9\s]*$/.test(value)) {
        error = "Only letters and numbers are allowed";
      }
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));

    setFormFields((prevFormFields) => ({
      ...prevFormFields,
      address: {
        ...prevFormFields.address,
        [name]: value,
      },
    }));
  };

  const changeInput2 = (e) => {
    setFields((prevFields) => ({
      ...prevFields,
      [e.target.name]: e.target.value,
    }));
  };

  const changeWalletAmount = (e) => {
    const value = e.target.value;
    if (!/^\d+$/.test(value)) {
      setWalletError("Only numbers are allowed");
    } else {
      setWalletError("");
    }
    setWalletAmount(value);
  };

  let img_arr = [];
  let uniqueArray = [];
  let selectedImages = [];

  const onChangeFile = async (e, apiEndPoint) => {
    try {
      setPreviews([]);

      const files = e.target.files;

      setUploading(true);

      for (var i = 0; i < files.length; i++) {
        if (
          files[i] &&
          (files[i].type === "image/jpeg" ||
            files[i].type === "image/jpg" ||
            files[i].type === "image/png" ||
            files[i].type === "image/webp")
        ) {
          const file = files[i];
          selectedImages.push(file);
          formdata.append(`images`, file);
        } else {
          context.setAlertBox({
            open: true,
            error: true,
            msg: "Please select a valid JPG or PNG image file.",
          });

          return false;
        }
      }

      formFields.images = selectedImages;
      selectedImages.push(selectedImages);
    } catch (error) {
      console.log(error);
    }

    uploadImage(apiEndPoint, formdata).then((res) => {
      fetchDataFromApi("/api/imageUpload").then((response) => {
        if (
          response !== undefined &&
          response !== null &&
          response !== "" &&
          response.length !== 0
        ) {
          response.length !== 0 &&
            response.map((item) => {
              item?.images.length !== 0 &&
                item?.images?.map((img) => {
                  img_arr.push(img);
                });
            });

          uniqueArray = img_arr.filter(
            (item, index) => img_arr.indexOf(item) === index
          );

          setPreviews(uniqueArray);

          const user = JSON.parse(localStorage.getItem("user"));

          fetchDataFromApi(`/api/user/${user?.userId}`).then((res) => {
            const data = {
              name: res?.name,
              email: res?.email,
              phone: res?.phone,
              images: uniqueArray,
              isAdmin: res?.isAdmin,
              address: res?.address,
            };

            editData(`/api/user/${user?.userId}`, data).then((res) => {
              setTimeout(() => {
                setUploading(false);
                img_arr = [];
                context.setAlertBox({
                  open: true,
                  error: false,
                  msg: "Images Uploaded!",
                });
                setUploading(false);
              }, 200);
            }).catch((error) => {
              context.setAlertBox({
                open: true,
                error: true,
                msg: "Failed to upload images",
              });
            });
          }).catch((error) => {
            context.setAlertBox({
              open: true,
              error: true,
              msg: "Failed to fetch user data",
            });
          });
        }
      }).catch((error) => {
        context.setAlertBox({
          open: true,
          error: true,
          msg: "Failed to fetch image upload data",
        });
      });
    }).catch((error) => {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Failed to upload images",
      });
    });
  };

  const edituser = (e) => {
    e.preventDefault();

    const updatedFields = {
      name: formFields.name,
      email: formFields.email,
      phone: formFields.phone,
      address: formFields.address,
      images: formFields.images,
    };

    if (
      (formFields.name !== "" &&
        formFields.email !== "" &&
        formFields.phone !== "") || (formFields.address.fullName !== "" &&
          formFields.address.country !== "" &&
          formFields.address.streetAddressLine1 !== "" &&
          formFields.address.townCity !== "" &&
          formFields.address.state !== "" &&
          formFields.address.postCode !== "")

    ) {
      setIsLoading(true);
      const user = JSON.parse(localStorage.getItem("user"));

      editData(`/api/user/${user?.userId}`, updatedFields).then((res) => {
        setIsLoading(false);

        context.setAlertBox({
          open: true,
          error: false,
          msg: "user updated",
        });
      }).catch((error) => {
        setIsLoading(false);
        context.setAlertBox({
          open: true,
          error: true,
          msg: "Failed to update user",
        });
      });
    } else {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please fill all the details",
      });
      return false;
    }
  };

  const changePassword = (e) => {
    e.preventDefault();
    formdata.append("password", fields.password);

    if (
      fields.oldPassword !== "" &&
      fields.password !== "" &&
      fields.confirmPassword !== ""
    ) {
      if (fields.password !== fields.confirmPassword) {
        context.setAlertBox({
          open: true,
          error: true,
          msg: "Password and confirm password not match",
        });
      } else {
        const user = JSON.parse(localStorage.getItem("user"));

        const data = {
          name: user?.name,
          email: user?.email,
          password: fields.oldPassword,
          newPass: fields.password,
          phone: formFields.phone,
          images: formFields.images,
        };
        editData(`/api/user/changePassword/${user.userId}`, data).then(
          (res) => {
            setIsLoading(false);
            context.setAlertBox({
              open: true,
              error: false,
              msg: "Password changed successfully",
            });
          }
        ).catch((error) => {
          setIsLoading(false);
          context.setAlertBox({
            open: true,
            error: true,
            msg: "Failed to change password",
          });
        });
      }
    } else {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please fill all the details",
      });
      return false;
    }
  };

  const addAddress = async (e) => {
    e.preventDefault();
    const user = JSON.parse(localStorage.getItem("user"));
    let updatedAddresses;
    if (editingAddressId) {
      await editData(`/api/user/${user.userId}/address/${editingAddressId}`, formFields.address).then(() => {
        updatedAddresses = userData.addresses.map((address) =>
          address._id === editingAddressId ? formFields.address : address
        );
        setEditingAddressId(null);
      }).catch((error) => {
        context.setAlertBox({
          open: true,
          error: true,
          msg: "Failed to update address",
        });
      });
    } else {
      await postData(`/api/user/${user.userId}/address`, formFields.address).then((newAddress) => {
        updatedAddresses = [...userData.addresses, newAddress];
      }).catch((error) => {
        context.setAlertBox({
          open: true,
          error: true,
          msg: "Failed to add address",
        });
      });
    }
    setUserData({ ...userData, addresses: updatedAddresses });
    setFormFields({
      ...formFields,
      address: {
        fullName: "",
        country: "",
        streetAddressLine1: "",
        streetAddressLine2: "",
        townCity: "",
        state: "",
        postCode: "",
      },
    });
    context.setAlertBox({ open: true, msg: editingAddressId ? "Address updated!" : "Address added!", error: false });
    fetchDataFromApi(`/api/user/${user?.userId}`).then((res) => {
      setUserData(res);
    }).catch((error) => {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Failed to fetch user data",
      });
    });
  };

  const deleteAddress = async (addressId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    await deleteData(`/api/user/${user.userId}/address/${addressId}`).then(() => {
      const updatedAddresses = userData.addresses.filter((address) => address._id !== addressId);
      setUserData({ ...userData, addresses: updatedAddresses });
      context.setAlertBox({ open: true, msg: "Address deleted!", error: false });
      fetchDataFromApi(`/api/user/${user?.userId}`).then((res) => {
        setUserData(res);
      }).catch((error) => {
        context.setAlertBox({
          open: true,
          error: true,
          msg: "Failed to fetch user data",
        });
      });
    }).catch((error) => {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Failed to delete address",
      });
    });
  };

  const editAddress = (address) => {
    setEditingAddressId(address._id);
    setFormFields({
      ...formFields,
      address: {
        fullName: address.fullName,
        country: address.country,
        streetAddressLine1: address.streetAddressLine1,
        streetAddressLine2: address.streetAddressLine2,
        townCity: address.townCity,
        state: address.state,
        postCode: address.postCode,
      },
    });
  };

  const addFunds = () => {
    if (walletError) {
      context.setAlertBox({
        open: true,
        error: true,
        msg: walletError,
      });
      return;
    }

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      amount: walletAmount * 100, // Amount in paise
      currency: "INR",
      name: "Your Company Name",
      description: "Add funds to wallet",
      handler: function (response) {
        // API call after successful payment
        postData("/api/user/updateWallet", {
          userId: userData._id,
          walletBalance: walletAmount, // Send only the added amount
        }).then(() => {
          setModalMessage("Payment successful! Amount added to wallet.");
          setModalIsOpen(true);
          fetchDataFromApi(`/api/user/${userData._id}`).then((res) => {
            setUserData(res);
          }).catch((error) => {
            context.setAlertBox({
              open: true,
              error: true,
              msg: "Failed to fetch user data",
            });
          });
        }).catch((error) => {
          context.setAlertBox({
            open: true,
            error: true,
            msg: "Failed to update wallet balance",
          });
        });
      },
      prefill: {
        name: userData.name,
        email: userData.email,
        contact: userData.phone,
      },
      theme: {
        color: "#3399cc",
      },
      modal: {
        ondismiss: function () {
          setModalMessage("Transaction failed, please try after some time.");
          setModalIsOpen(true);
        },
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <section className="section myAccountPage" style={{ overflowY: "auto" }}>
      <div className="container">
        <h2 className="hd">My Account</h2>

        <Box sx={{ width: "100%" }} className="myAccBox card border-0">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Edit Profile" {...a11yProps(0)} />
              <Tab label="Change Password" {...a11yProps(1)} />
              <Tab label="Wallet" {...a11yProps(2)} />
              <Tab label="Address" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <form onSubmit={edituser}>
              <div className="row">
                <div className="col-md-4">
                  <div className="userImage d-flex align-items-center justify-content-center">
                    {uploading === true ? (
                      <CircularProgress />
                    ) : (
                      <>
                        {previews?.length !== 0 ? (
                          previews?.map((img, index) => {
                            return <img src={img} key={index} />;
                          })
                        ) : (
                          <img src={NoUserImg} />
                        )}
                        <div className="overlay d-flex align-items-center justify-content-center">
                          <IoMdCloudUpload />
                          <input
                            type="file"
                            multiple
                            onChange={(e) =>
                              onChangeFile(e, "/api/user/upload")
                            }
                            name="images"
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <TextField
                          label="Full Name"
                          variant="outlined"
                          className="w-100"
                          name="name"
                          onChange={changeInput}
                          value={formFields.name}
                          error={!!errors.name}
                          helperText={errors.name}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <TextField
                          label="Email"
                          disabled
                          variant="outlined"
                          className="w-100"
                          name="email"
                          onChange={changeInput}
                          value={formFields.email}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <TextField
                          label="Phone"
                          variant="outlined"
                          className="w-100"
                          name="phone"
                          onChange={changeInput}
                          value={formFields.phone}
                          error={!!errors.phone}
                          helperText={errors.phone}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <Button
                      type="submit"
                      className="btn-g btn-lg btn-big"
                    >
                      {isLoading === true ? <CircularProgress /> : "Save"}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <form onSubmit={changePassword}>
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <TextField
                          label="Old Password"
                          variant="outlined"
                          className="w-100"
                          name="oldPassword"
                          onChange={changeInput2}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <TextField
                          label="New password"
                          variant="outlined"
                          className="w-100"
                          name="password"
                          onChange={changeInput2}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <TextField
                          label="Confirm Password"
                          variant="outlined"
                          className="w-100"
                          name="confirmPassword"
                          onChange={changeInput2}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <Button
                      type="submit"
                      className="btn-g btn-lg btn-big"
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
  <div className="wallet-section">
    <h3>Wallet Balance: ₹{userData.walletBalance}</h3>
    <TextField
      label="Amount"
      variant="outlined"
      className="w-100"
      name="walletAmount"
      onChange={changeWalletAmount}
      value={walletAmount}
      error={!!walletError}
      helperText={walletError}
    />
    <Button
      className="btn-g btn-lg btn-big mt-3"
      onClick={addFunds}
    >
      Add Funds
    </Button>

    {/* Wallet Transaction History */}
    <Box mt={2}>
      <Typography variant="h6" gutterBottom>
        Wallet Transaction History
      </Typography>
      {loadingTransactions ? (
        <CircularProgress />
      ) : walletTransactions.length === 0 ? (
        <Typography>No transactions available.</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Reason</TableCell>
                <TableCell>Balance After</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {walletTransactions.map((transaction, index) => (
                <TableRow key={index}>
                  <TableCell style={{ color: transaction.transactionType === "credit" ? "green" : "red" }}>
                    {transaction.transactionType.toUpperCase()}
                  </TableCell>
                  <TableCell>₹{transaction.amount.toFixed(2)}</TableCell>
                  <TableCell>{transaction.reason}</TableCell>
                  <TableCell>₹{transaction.balanceAfterTransaction.toFixed(2)}</TableCell>
                  <TableCell>
                    {new Date(transaction.timestamp).toLocaleDateString()}{" "}
                   
                 
                  </TableCell>
                  <TableCell>
                   
                   
                    {new Date(transaction.timestamp).toLocaleTimeString('en-IN', { hour: '2-digit', minute: '2-digit', hour12: true })}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  </div>
</CustomTabPanel>

          <CustomTabPanel value={value} index={3}>
            <div>
              <h3>Add/Edit Address</h3>
              <form onSubmit={addAddress}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <TextField
                        label="Full Name *"
                        variant="outlined"
                        className="w-100"
                        required
                        size="small"
                        name="fullName"
                        onChange={changeAddressInput}
                        value={formFields.address.fullName}
                        error={!!errors.fullName}
                        helperText={errors.fullName}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <TextField
                        label="Country *"
                        variant="outlined"
                        className="w-100"
                        size="small"
                        required
                        name="country"
                        onChange={changeAddressInput}
                        value={formFields.address.country}
                        error={!!errors.country}
                        helperText={errors.country}
                      />
                    </div>
                  </div>
                </div>

                <h6>Street address *</h6>

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <TextField
                        label="House number and street name"
                        variant="outlined"
                        className="w-100"
                        size="small"
                        required
                        name="streetAddressLine1"
                        onChange={changeAddressInput}
                        value={formFields.address.streetAddressLine1}
                        error={!!errors.streetAddressLine1}
                        helperText={errors.streetAddressLine1}
                      />
                    </div>

                    <div className="form-group">
                      <TextField
                        label="Apartment, suite, unit, etc. (optional)"
                        variant="outlined"
                        className="w-100"
                        size="small"
                        name="streetAddressLine2"
                        onChange={changeAddressInput}
                        value={formFields.address.streetAddressLine2}
                        error={!!errors.streetAddressLine2}
                        helperText={errors.streetAddressLine2}
                      />
                    </div>
                  </div>
                </div>

                <h6>Town / City *</h6>

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <TextField
                        label="City"
                        variant="outlined"
                        className="w-100"
                        size="small"
                        required
                        name="townCity"
                        onChange={changeAddressInput}
                        value={formFields.address.townCity}
                        error={!!errors.townCity}
                        helperText={errors.townCity}
                      />
                    </div>
                  </div>
                </div>

                <h6>State / County *</h6>

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <TextField
                        label="State"
                        variant="outlined"
                        className="w-100"
                        size="small"
                        required
                        name="state"
                        onChange={changeAddressInput}
                        value={formFields.address.state}
                        error={!!errors.state}
                        helperText={errors.state}
                      />
                    </div>
                  </div>
                </div>

                <h6>ZIP / Postcode *</h6>

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <TextField
                        label="ZIP"
                        variant="outlined"
                        className="w-100"
                        size="small"
                        required
                        name="postCode"
                        onChange={changeAddressInput}
                        value={formFields.address.postCode}
                        error={!!errors.postCode}
                        helperText={errors.postCode}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group mt-3">
                  <Button
                    type="submit"
                    className="btn-g btn-lg btn-big"
                  >
                    {isLoading === true ? <CircularProgress /> : "Save"}
                  </Button>
                </div>
              </form>
              <h2>My Addresses</h2>

              {userData.addresses?.map((address, index) => (
                <Card key={index} className="addressCard" sx={{ mb: 2, p: 2, borderRadius: 2, boxShadow: 3 }}>
                  <CardContent>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      {address.fullName}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {address.streetAddressLine1}
                    </Typography>
                    {address.streetAddressLine2 && (
                      <Typography variant="body2" color="textSecondary">
                        {address.streetAddressLine2}
                      </Typography>
                    )}
                    <Typography variant="body2" color="textSecondary">
                      {address.townCity}, {address.state} - {address.postCode}
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<Edit />}
                        onClick={() => editAddress(address)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        startIcon={<Delete />}
                        onClick={() => deleteAddress(address._id)}
                      >
                        Delete
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              ))}

            </div>
          </CustomTabPanel>
        </Box>
      </div>
      <Modal
  isOpen={modalIsOpen}
  onRequestClose={closeModal}
  contentLabel="Information"
  style={{
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "20px",
      borderRadius: "10px",
      maxWidth: "400px",
      width: "100%",
      backgroundColor: "#f9f9f9",
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      border: "none",
    },
  }}
>
  <div style={{ textAlign: "center" }}>
    <h2 style={{ color: "#333", fontWeight: "bold" }}>Information</h2>
    <p style={{ color: "#555", marginBottom: "20px" }}>{modalMessage}</p>
    <button
      onClick={closeModal}
      style={{
        backgroundColor: "#4CAF50",
        color: "#fff",
        padding: "10px 20px",
        fontSize: "16px",
        borderRadius: "5px",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  </div>
</Modal>

    </section>
  );
};

export default MyAccount;
