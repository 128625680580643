import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Product from "../../components/product";
import { Button, CircularProgress, Pagination } from "@mui/material";
import { MyContext } from "../../App";
import { fetchDataFromApi } from "../../utils/api";

const Listing = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [allProducts, setAllProducts] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortOption, setSortOption] = useState("");
  const [currentCat, setCurrentCat] = useState(null);
  const [activePriceRange, setActivePriceRange] = useState([100, 60000]);
  const [activeRating, setActiveRating] = useState(null);

  const context = useContext(MyContext);
  const { id } = useParams();
  const itemsPerPage = 12;

  // Fetch products by category or subcategory ID
  const fetchAllProducts = useCallback(async () => {
    setIsLoading(true);
    const location = localStorage.getItem("location");
    const isSubCategory = window.location.href.includes("subCat");
    const apiEndPoint = isSubCategory
      ? `/api/products/subCatId?subCatId=${id}&location=${location}`
      : `/api/products/catId?catId=${id}&location=${location}`;

    try {
      let products = [];
      let totalApiPages = 1;
      let currentApiPage = 1;

      do {
        const res = await fetchDataFromApi(`${apiEndPoint}&page=${currentApiPage}&limit=100`);
        products = [...products, ...(res.products || [])];
        totalApiPages = res.totalPages || 1;
        currentApiPage++;
      } while (currentApiPage <= totalApiPages);

      products = products.filter(product => !product.isDeleted);

      // Set initial product state
      setAllProducts(products);
      setFilteredProducts(products); // Default filtered products
      setTotalPages(Math.ceil(products.length / itemsPerPage));
    } finally {
      setIsLoading(false);
    }

    const categoryRes = await fetchDataFromApi(`/api/category/${id}`);
    setCurrentCat(categoryRes);
    context.setEnableFilterTab(true);
  }, [id, context]);

  // Fetch products on mount or when category ID changes
  useEffect(() => {
    fetchAllProducts();
  }, [fetchAllProducts]);

  // Apply filters and sorting whenever relevant state changes
  useEffect(() => {
    const applyFiltersAndSorting = () => {
      let filtered = [...allProducts];

      // Price Range Filter
      const [minPrice, maxPrice] = activePriceRange;
      filtered = filtered.filter(product => product.price >= minPrice && product.price <= maxPrice);

      // Rating Filter
      if (activeRating !== null) {
        filtered = filtered.filter(product => product.rating >= activeRating);
      }

      // Sorting
      if (sortOption) {
        switch (sortOption) {
          case "price-asc":
            filtered.sort((a, b) => a.price - b.price);
            break;
          case "price-desc":
            filtered.sort((a, b) => b.price - a.price);
            break;
          case "rating":
            filtered.sort((a, b) => b.rating - a.rating);
            break;
          case "a-z":
            filtered.sort((a, b) => a.name.localeCompare(b.name));
            break;
          case "z-a":
            filtered.sort((a, b) => b.name.localeCompare(a.name));
            break;
          case "new-arrivals":
            filtered.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated));
            break;
          default:
            break;
        }
      }

      setFilteredProducts(filtered);
      setTotalPages(Math.ceil(filtered.length / itemsPerPage));
      setPage(1); // Reset to first page when filters or sorting change
    };

    applyFiltersAndSorting();
  }, [allProducts, sortOption, activePriceRange, activeRating]);

  // Paginate displayed products when page or filtered products change
  useEffect(() => {
    const paginate = () => {
      const startIdx = (page - 1) * itemsPerPage;
      const paginatedProducts = filteredProducts.slice(startIdx, startIdx + itemsPerPage);
      setDisplayedProducts(paginatedProducts);
    };

    paginate();
  }, [page, filteredProducts]);

  // Handle page changes
  const handlePageChange = (event, value) => setPage(value);

  // Handle search functionality
  const searchBy = (searchTerm) => {
    const lowerCaseSearch = searchTerm.toLowerCase();
    const searchedProducts = allProducts.filter((product) =>
      product.name.toLowerCase().includes(lowerCaseSearch)
    );
    setFilteredProducts(searchedProducts);
    setTotalPages(Math.ceil(searchedProducts.length / itemsPerPage));
    setPage(1); // Reset to the first page
  };

  return (
    <>
      {context.windowWidth < 992 && context?.isOpenFilters && (
        <Button
          className="btn-g btn-lg w-100 filterBtn"
          onClick={() => context.openFilters()}
        >
          Filters
        </Button>
      )}

      <section className="listingPage">
        <div className="container-fluid">
          <div className="breadcrumb flex-column">
            <h1 className="text-capitalize">
              {currentCat?.categoryData?.[0]?.name}
            </h1>
            <ul className="list list-inline mb-0">
              <li className="list-inline-item">
                <Link to="/">Home</Link>
              </li>
              <li className="list-inline-item">
                <Link
                  to={`/products/category/${filteredProducts?.[0]?.catId}`}
                  className="text-capitalize"
                >
                  {filteredProducts?.[0]?.catName}
                </Link>
              </li>
            </ul>
          </div>

          <div className="listingData">
            <div className="row">
              <div className="col-md-3 sidebarWrapper">
                <Sidebar
                  filterByRating={(rating) => setActiveRating(rating)}
                  filterByPrice={(range) => setActivePriceRange(range)}
                  sortBy={(option) => setSortOption(option)}
                  searchBy={searchBy} // Pass searchBy function to Sidebar
                  catId={id}
                />
              </div>

              <div className="col-md-9 rightContent homeProducts pt-0">
                <div className="topStrip d-flex align-items-center">
                  <p className="mb-0">
                    We found{" "}
                    <span className="text-success">
                      {filteredProducts.length}
                    </span>{" "}
                    items for you!
                  </p>
                </div>
                <div className="productRow pl-4 pr-3">
                  {isLoading ? (
                    <div className="loading d-flex align-items-center justify-content-center">
                      <CircularProgress color="inherit" />
                    </div>
                  ) : (
                    displayedProducts.map((item, index) => (
                      <div className="item" key={item.id || index}>
                        <Product item={item} />
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="paginationWrapper d-flex justify-content-center mt-4">
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
            className="pagination"
            showFirstButton
            showLastButton
          />
        </div>
      </section>
    </>
  );
};

export default Listing;
