import React, { useContext, useEffect, useState } from "react";
import { fetchDataFromApi, postData } from "../../utils/api";
import Pagination from "@mui/material/Pagination";
import Dialog from "@mui/material/Dialog";
import { MdClose, MdDownload, MdRefresh } from "react-icons/md";
import Button from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import { MyContext } from "../../App";
import JsBarcode from 'jsbarcode';

import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Box,
  Avatar,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [isReturnDialogOpen, setIsReturnDialogOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [returnReason, setReturnReason] = useState("");
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isOrderCancelled, setIsOrderCancelled] = useState(false);
  const [isOrderReturned, setIsOrderReturned] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1350);
  const [isOrderDelivered, setIsOrderDelivered] = useState(false);
  const [isOrderFailed, setIsOrderFailed] = useState(false); // Added isOrderFailed
  const context = useContext(MyContext);
  const history = useNavigate();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    const token = localStorage.getItem("token");
    if (token) {
      setIsLogin(true);
    } else {
      history("/signIn");
    }

    const user = JSON.parse(localStorage.getItem("user"));
    fetchDataFromApi(`/api/orders?userid=${user?.userId}`).then((res) => {
      setOrders(res);
    }).catch((error) => {
      console.error("Error fetching orders:", error);
    });

    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1350);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    fetchDataFromApi(`/api/orders?userid=${user?.userId}`).then((res) => {
      setOrders(res);
    }).catch((error) => {
      console.error("Error fetching orders:", error);
    });
  }, [page]);

  const refreshOrders = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    fetchDataFromApi(`/api/orders?userid=${user?.userId}`).then((res) => {
      setOrders(res);
    }).catch((error) => {
      console.error("Error fetching orders:", error);
    });
  };

  const showProducts = (orderId) => {
    fetchDataFromApi(`/api/orders/${orderId}`).then((res) => {
      setIsOpenModal(true);
      setProducts(res.products);
      setSelectedOrderId(orderId);
      setIsOrderCancelled(res.status === "cancelled");
      setIsOrderReturned(res.status === "returned");
      setIsOrderDelivered(res.status === "delivered");
      setIsOrderFailed(res.status === "payment failed"); // Added isOrderFailed
    }).catch((error) => {
      console.error("Error fetching products:", error);
    });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const openCancelDialog = (orderId, productId) => {
    setSelectedOrderId(orderId);
    setSelectedProductId(productId);
    setIsCancelDialogOpen(true);
  };

  const openReturnDialog = (orderId, productId) => {
    setSelectedOrderId(orderId);
    setSelectedProductId(productId);
    setIsReturnDialogOpen(true);
  };

  const handleCancelProduct = async () => {
    if (!cancelReason) {
      setAlertMessage("Please provide a reason for cancellation.");
      setIsAlertOpen(true);
      return;
    }
  
    if (!selectedOrderId || !selectedProductId) {
      setAlertMessage("Invalid order or product selection.");
      setIsAlertOpen(true);
      return;
    }
  
    const productToCancel = products.find(
      (product) => product.productId === selectedProductId
    );
  
    if (!productToCancel) {
      setAlertMessage("Product not found.");
      setIsAlertOpen(true);
      return;
    }
  
    const orderToCancel = orders.find((order) => order._id === selectedOrderId);
  
    if (!orderToCancel) {
      setAlertMessage("Order not found.");
      setIsAlertOpen(true);
      return;
    }
  
    // Access the amount from the found order
    const refundAmount = orderToCancel.amount;
    console.log("refundAmount:", refundAmount);
    console.log("selectedProductId:", productToCancel);
  
    try {
      const response = await postData(
        `/api/orders/${selectedOrderId}/cancel/${selectedProductId}`, 
        { reason: cancelReason, refundAmount }
      );
  
      if (response.order) {
        setAlertMessage(
          `Product cancelled successfully. The wallet balance is now ₹${response.walletBalance}.`
        );
        setIsAlertOpen(true);
  
        // Update products state to reflect the cancellation status
        setProducts(response.order.products);
  
        // Update wallet balance in the context
        context.setUserWalletBalance(response.walletBalance);
  
        // Reflect updated product status in UI immediately
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order._id === selectedOrderId ? response.order : order
          )
        );
  
        if (response.order.status === "cancelled") {
          setProducts((prevProducts) =>
            prevProducts.map((product) =>
              product.productId === selectedProductId
                ? { ...product, status: "cancelled" }
                : product
            )
          );
        }
      } else {
        console.error("Failed to cancel the product:", response.message);
        setAlertMessage("Failed to cancel the product.");
      }
    } catch (error) {
      console.error("Error cancelling product:", error);
      setAlertMessage("Error cancelling product.");
      setIsAlertOpen(true);
    } finally {
      setIsCancelDialogOpen(false);
      setCancelReason("");
    }
  };
  const handleReturnProduct = async () => {
    if (!returnReason) {
      setAlertMessage("Please provide a reason for return.");
      setIsAlertOpen(true);
      return;
    }

    if (!selectedOrderId || !selectedProductId) {
      setAlertMessage("Invalid order or product selection.");
      setIsAlertOpen(true);
      return;
    }

    const productToReturn = products.find(
      (product) => product.productId === selectedProductId
    );

    if (!productToReturn) {
      setAlertMessage("Product not found.");
      setIsAlertOpen(true);
      return;
    }

    const orderToReturn = orders.find((order) => order._id === selectedOrderId);

    if (!orderToReturn) {
      setAlertMessage("Order not found.");
      setIsAlertOpen(true);
      return;
    }

    const refundAmount = orderToReturn.amount;

    try {
      const response = await postData(
        `/api/orders/${selectedOrderId}/return/${selectedProductId}`,
        { reason: returnReason, refundAmount }
      );

      if (response.order) {
        setAlertMessage(
          `Product returned successfully. The wallet balance is now ₹${response.walletBalance}.`
        );
        setIsAlertOpen(true);

        setProducts(response.order.products);

        context.setUserWalletBalance(response.walletBalance);

        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order._id === selectedOrderId ? response.order : order
          )
        );

        if (response.order.status === "returned") {
          setProducts((prevProducts) =>
            prevProducts.map((product) =>
              product.productId === selectedProductId
                ? { ...product, status: "returned" }
                : product
            )
          );
        }
      } else {
        console.error("Failed to return the product:", response.message);
        setAlertMessage("Failed to return the product.");
      }
    } catch (error) {
      console.error("Error returning product:", error);
      setAlertMessage("Error returning product.");
      setIsAlertOpen(true);
    } finally {
      setIsReturnDialogOpen(false);
      setReturnReason("");
    }
  };
  const downloadInvoice = (order) => {
    const doc = new jsPDF();
  
    // Set up the header
    doc.setFontSize(22);
    doc.setTextColor("#4CAF50");
    doc.text("Invoice", 105, 20, { align: "center" });
  
    // Add general order details
    doc.setFontSize(12);
    doc.setTextColor("#000000");
    doc.text(`Order ID: ${order.id.substring(0, 11)}`, 20, 40);
    doc.text(`Payment ID: ${order.paymentId}`, 20, 50);
    doc.text(`Customer Name: ${order.name}`, 20, 60);
    doc.text(`Phone Number: ${order.phoneNumber}`, 20, 70);
    doc.text(`Email: ${order.email}`, 20, 80);
    doc.text(`Address: ${order.address}`, 20, 90);
    doc.text(`Order Date: ${order.date?.split("T")[0]}`, 20, 100);
  
    // Add product details
    const productsData = order.products.map((product, index) => [
      index + 1,
      product.productTitle,
      product.quantity,
      parseFloat(product.price).toFixed(2),
      parseFloat(product.subTotal).toFixed(2),
    ]);
  
    doc.autoTable({
      head: [["#", "Product", "Quantity", "Price ", "Subtotal "]],
      body: productsData,
      startY: 120,
      theme: "grid",
      styles: {
        fontSize: 10,
        cellPadding: 4,
        lineColor: [44, 62, 80],
        lineWidth: 0.1,
      },
      headStyles: { fillColor: "#4CAF50", textColor: 255, fontStyle: "bold", halign: "center" },
      margin: { left: 20, right: 20 },
    });
  
    // Calculate totals
    const subtotal = order.products.reduce((sum, product) => sum + product.subTotal, 0);
    const totalAmount = parseFloat(order.amount); // Ensure amount is treated as a number
    const discount = subtotal - totalAmount; // Calculate discount
    const discountPercentage = ((discount / subtotal) * 100).toFixed(2);
  
    // Display totals
    const finalY = doc.autoTable.previous.finalY + 10;
  
    doc.setFontSize(12);
    
    doc.setTextColor("#000000");
    doc.text(`Subtotal: ${subtotal.toFixed(2)}`, 20, finalY);
  
    // Conditionally show discount only if it exists
    if (discount > 0) {
      doc.setTextColor("#000000");
      doc.text(`Discount: ${discount.toFixed(2)} (`, 20, finalY + 10);
      doc.setTextColor("#4db04f"); // Set text color to green
      doc.text(`${discountPercentage}%`, doc.getTextWidth(`Discount: ${discount.toFixed(2)} (`) + 20, finalY + 10);
      doc.setTextColor("#000000"); // Reset text color to black
      doc.text(`)`, doc.getTextWidth(`Discount: ${discount.toFixed(2)} (${discountPercentage}%`) + 20, finalY + 10);
      doc.text(`Total Amount: ${totalAmount.toFixed(2)}`, 20, finalY + 20);
    } else {
      doc.text(`Total Amount: ${totalAmount.toFixed(2)}`, 20, finalY + 10);
    }

    // Add generated date
    const generatedDate = new Date(new Date(order.date).getTime() + 24 * 60 * 60 * 1000).toLocaleDateString();
    doc.text(`Generated Date: ${generatedDate}`, 20, finalY + (discount > 0 ? 30 : 20));
  
    // Add barcode above the footer with correct spacing and aligned to the right
    const barcodeY = finalY + (discount > 0 ? 40 : 30); // Adjust position above the footer
    const pageWidth = doc.internal.pageSize.width;
    const barcodeWidth = 50;
    const barcodeX = pageWidth - barcodeWidth - 15; // Right align the barcode
    doc.addImage(generateBarcode(order.id), 'JPEG', barcodeX, barcodeY, barcodeWidth, 20);
  
    // Footer
    doc.setFontSize(10);
    doc.setTextColor("#888888");
    doc.text("Thank you for your purchase!", 105, finalY + (discount > 0 ? 60 : 50), { align: "center" });
  
    // Add a visible border to the entire PDF with padding
    doc.setDrawColor(77, 176, 79); // Set draw color to #4db04f
    doc.setLineWidth(1.6);
    doc.rect(5, 5, doc.internal.pageSize.width - 10, doc.internal.pageSize.height - 10); // Draw a rectangle border around the page with padding
  

    // Save the PDF
    doc.save(`invoice_${order.id}.pdf`);
  };

  // Helper function to generate barcode
  function generateBarcode(data) {
    const JsBarcode = require('jsbarcode');
    const { createCanvas } = require('canvas');
    const canvas = createCanvas();
    JsBarcode(canvas, data, { format: 'CODE128' });
    return canvas.toDataURL('image/jpeg');
  }


  const handleRetryPayment = async (order) => {
    // Ensure the Razorpay SDK is loaded
    if (!window.Razorpay) {
      setAlertMessage("Razorpay SDK not loaded. Please try again later.");
      setIsAlertOpen(true);
      return;
    }
  
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Replace with your Razorpay Key ID
      amount: order.amount * 100, // Amount in paise
      currency: "INR",
      name: "Shop Me",
      description: `Retry payment for Order ID: ${order.id}`,
      handler: async function (response) {
        const paymentId = response.razorpay_payment_id;
  
        try {
          // Send payment details to the server
          const res = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/orders/${order.id}/retry`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ paymentId }),
            }
          );
  
          const data = await res.json();
  
          if (data.success) {
            // Update the UI state immediately
            setOrders((prevOrders) =>
              prevOrders.map((o) =>
                o._id === order.id ? { ...o, status: "pending", paymentId } : o
              )
            );
  
            // Show success alert
            setAlertMessage("Payment retried successfully.");
            setIsAlertOpen(true);
  
            // Navigate to payment success page
            navigate("/payment-success");
          } else {
            refreshOrders();
            context.setAlertBox({
              open: true,
              error: false,
              msg: 'Payment retried successfully',
            });
          }
        } catch (error) {
          console.error("Error retrying payment:", error);
          setAlertMessage("Error retrying payment. Please try again.");
          setIsAlertOpen(true);
        }
      },
      prefill: {
        name: order.name,
        email: order.email,
        contact: order.phoneNumber,
      },
      theme: {
        color: "#3399cc",
      },
      modal: {
        ondismiss: function () {
          console.log("Payment retry canceled.");
          setAlertMessage("Payment retry canceled.");
          setIsAlertOpen(true);
        },
      },
    };
  
    const razorpay = new window.Razorpay(options);
    razorpay.open();
  };
  
  

  return (
    <>
      <Box
        className="orderPage"
        sx={{ padding: "40px 20px", backgroundColor: "#f5f5f5" }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          My Orders
        </Typography>
        

        {isMobileView ? (
          <>
            {orders.slice((page - 1) * 3, page * 3).map((order, index) => (
              <Card key={index} sx={{ marginBottom: 2 }}>
                <CardContent>
                  <Typography variant="h6">Order Id: {order?.id}</Typography>
                  <Typography>Payment Id: {order?.paymentId}</Typography>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => showProducts(order._id)}
                    sx={{
                      backgroundColor: "#35ac75",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#2e8b57",
                      },
                    }}
                  >
                    View Products
                  </Button>
                  {order.status === "delivered" && (
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => downloadInvoice(order)}
                      sx={{
                        backgroundColor: "#35ac75",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#2e8b57",
                        },
                        marginLeft: 1,
                      }}
                    >
                      Download Invoice
                    </Button>
                  )}
                   <Button
                    variant="outlined"
                    size="small"
                    onClick={() => history(`/order/${order.id}`)}

                    sx={{
                      backgroundColor: "#35ac75",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#2e8b57",
                      },
                      marginLeft: 1,
                    }}
                  >
                    Order Details
                  </Button>
                  <Typography>Name: {order?.name}</Typography>
                  <Typography>Phone Number: {order?.phoneNumber}</Typography>
                  <Typography>Address: {order?.address}</Typography>
                  <Typography>Total Amount: {order?.amount}</Typography>
                  <Typography>Email: {order?.email}</Typography>
                  <Typography>
                    Status:{" "}
                    <span
                      style={{
                        color: order.status === "pending" ? "red" : order.status === "payment failed" ? "red" : "green",
                        fontWeight: "bold",
                      }}
                    >
                      {order?.status}
                      {order.status === "payment failed" && (
                        <MdRefresh
                          style={{ marginLeft: 5, cursor: "pointer" }}
                          onClick={() => handleRetryPayment(order)}
                        />
                      )}
                    </span>
                  </Typography>
                  <Typography>Date: {order?.date?.split("T")[0]}</Typography>
                </CardContent>
              </Card>
            ))}
            <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
              <Button
                variant="contained"
                onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                disabled={page === 1}
                sx={{ marginRight: 1 }}
              >
                Previous
              </Button>
              <Button
                variant="contained"
                onClick={() =>
                  setPage((prev) => (prev < Math.ceil(orders.length / 3) ? prev + 1 : prev))
                }
                disabled={page === Math.ceil(orders.length / 3)}
              >
                Next
              </Button>
            </Box>
          </>
        ) : (
          <TableContainer
            component={Paper}
            sx={{ margin: "20px auto", maxWidth: "95%" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Order Id</TableCell>
                  <TableCell>Payment Id</TableCell>
                  <TableCell>Products</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Total Amount</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Invoice</TableCell>
                  <TableCell>Order Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.slice((page - 1) * 3, page * 3).map((order, index) => (
                  <TableRow key={index}>
                    <TableCell>{order?.id}</TableCell>
                    <TableCell>{order?.paymentId}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => showProducts(order._id)}
                        sx={{
                          backgroundColor: "#35ac75",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#2e8b57",
                            
                          },
                        }}
                      >
                        View Products
                      </Button>
                    </TableCell>
                    <TableCell>{order?.name}</TableCell>
                    <TableCell>{order?.phoneNumber}</TableCell>
                    <TableCell>{order?.address}</TableCell>
                    <TableCell>{order?.amount}</TableCell>
                    <TableCell>{order?.email}</TableCell>
                    <TableCell>
  <span
    style={{
      color:
        order.status === "pending"
          ? "orange"
          : order.status === "payment failed" || order.status === "cancelled"
          ? "red"
          : "green",
      fontWeight: "bold",
    }}
  >
    {order.status}
    {order.status === "payment failed" && (
      <MdRefresh
        style={{ marginLeft: 5, cursor: "pointer" }}
        onClick={() => handleRetryPayment(order)}
      />
    )}
  </span>
</TableCell>

                    <TableCell>{order?.date?.split("T")[0]}</TableCell>
                    <TableCell>
                      {order.status === "delivered" && (
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => downloadInvoice(order)}
                          sx={{
                            backgroundColor: "#35ac75",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "#2e8b57",
                            },
                            minWidth: "auto", // Ensure the button is sized appropriately for an icon
                            padding: "6px", // Adjust padding for icon size
                          }}
                        >
                          <MdDownload />
                        </Button>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => history(`/order/${order.id}`)}
                        sx={{
                          backgroundColor: "#35ac75",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#2e8b57",
                          },
                          minWidth: "auto", // Ensure the button is sized appropriately for an icon
                          padding: "6px", // Adjust padding for icon size
                        }}
                      >
                      Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Pagination
          count={Math.ceil(orders.length / 3)}
          page={page}
          onChange={handlePageChange}
          sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}
        />
      </Box>

      <Dialog
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        maxWidth="md"
      >
        <Box sx={{ padding: "20px" }}>
          <Button onClick={() => setIsOpenModal(false)} sx={{ float: "right" }}>
            <MdClose />
          </Button>
          <Typography variant="h5" gutterBottom>
            Products
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product Id</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Subtotal</TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell>Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.productId || "No Product ID"}</TableCell>
                    <TableCell>
                      {item.productTitle?.substring(0, 30) || "No Title"}
                    </TableCell>
                    <TableCell>
                      <Avatar src={item.image || ""} variant="rounded" />
                    </TableCell>
                    <TableCell>{item.quantity || 0}</TableCell>
                    <TableCell>{item.price || "No Price"}</TableCell>
                    <TableCell>{item.subTotal || "No Subtotal"}</TableCell>
                    <TableCell>
                      {/* <Button variant="contained" onClick={() => handleViewDetails(item)}>View Details</Button> */}
                    </TableCell>
                    <TableCell>
                      {item.status === "cancelled" || isOrderCancelled ? (
                        <Button
                          variant="contained"
                          disabled
                          sx={{ backgroundColor: "red", color: "white" }}
                        >
                          Cancelled
                        </Button>
                      ) : item.status === "returned" || isOrderReturned ? (
                        <Button
                          variant="contained"
                          disabled
                          sx={{ backgroundColor: "grey", color: "white" }}
                        >
                          Returned
                        </Button>
                      ) : (
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() =>
                              openCancelDialog(selectedOrderId, item.productId)
                            }
                            disabled={isOrderDelivered || isOrderReturned || isOrderFailed}
                          >
                            Cancel
                          </Button>
                          {isOrderDelivered && (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                openReturnDialog(selectedOrderId, item.productId)
                              }
                              disabled={item.status === "returned" || isOrderReturned}
                            >
                              Return
                            </Button>
                          )}
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Dialog>

      <Dialog
        open={isCancelDialogOpen}
        onClose={() => setIsCancelDialogOpen(false)}
        maxWidth="sm"
      >
        <DialogTitle>
          <Typography variant="h6" gutterBottom>
            Reason for Cancellation
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={cancelReason}
            onChange={(e) => setCancelReason(e.target.value)}
            placeholder="Please provide a reason for cancellation"
            sx={{ marginBottom: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsCancelDialogOpen(false)}
            sx={{ marginRight: 1 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCancelProduct}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isReturnDialogOpen}
        onClose={() => setIsReturnDialogOpen(false)}
        maxWidth="sm"
      >
        <DialogTitle>
          <Typography variant="h6" gutterBottom>
            Reason for Return
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={returnReason}
            onChange={(e) => setReturnReason(e.target.value)}
            placeholder="Please provide a reason for return"
            sx={{ marginBottom: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsReturnDialogOpen(false)}
            sx={{ marginRight: 1 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleReturnProduct}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
                
      <Dialog
        open={isAlertOpen}
        onClose={() => setIsAlertOpen(false)}
        maxWidth="sm"
      >
        <DialogTitle>
          <Typography variant="h6" gutterBottom>
            Alert
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>{alertMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsAlertOpen(false)}
            sx={{ marginRight: 1 }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Orders;
